// src/App.js
import {React} from 'react';
import {CookiesProvider} from 'react-cookie';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Login from './component/common/Login';
import UserDashboard from './component/common/user/UserDashboard';
import TicketDashboard from "./component/admin/ticket/TicketDashboard";
import AgencySlotDashboard from './component/agency/slot/SlotDashboard';
import AgencyUserDashboard from './component/agency/user/UserDashboard';
import AgencyMyPage from './component/agency/mypage/MyPageDashboard';
import AdvertiserSlotDashboard from './component/advertiser/slot/SlotDashboard';
import AgencySlotHistoryDashboard from "./component/agency/slot-history/SlotHistoryDashboard";

import SlotDashboard from './component/common/slot/SlotDashboard';
import SlotManagement from './component/common/slot/SlotManagement';
import UserManagement from "./component/common/user/UserManagement";
import SlotHistoryDashboard from "./component/common/slot-history/SlotHistoryDashboard";
import SlotHistoryManagement from "./component/common/slot-history/SlotHistoryManagement";

function App() {
    const admin = "admin";
    const agency = "agency";
    const adv = "adv";

    const userUrl = "user-board";
    const slotUrl = "slot-board";
    const slotHistoryUrl = "slot-history-board";
    const ticketUrl = "ticket-board";
    const myPage = "my-page";


    return (
        <CookiesProvider>
            <BrowserRouter>
                <Routes>
                    <Route index path="/" element={<Login/>}/>
                    <Route path={`/${admin}/${userUrl}`}
                           element={<UserDashboard navigateUrl={`/${admin}/${userUrl}`}
                                                   userManagement={<UserManagement userType={admin}/>}/>}/>
                    <Route path={`/${admin}/${ticketUrl}`} element={<TicketDashboard/>}/>
                    <Route path={`/${admin}/${slotUrl}`}
                           element={<SlotDashboard navigateUrl={`/${admin}/${slotUrl}`}
                                                   slotManagement={<SlotManagement userType={admin}/>}/>}/>
                    <Route path={`/${admin}/${slotHistoryUrl}`}
                           element={<SlotHistoryDashboard navigateUrl={`/${admin}/${slotHistoryUrl}`}
                                                          slotHistoryManagement={<SlotHistoryManagement
                                                              userType={admin}/>}/>}/>

                    <Route path={`/${agency}/${userUrl}`}
                           element={<UserDashboard navigateUrl={`/${agency}/${userUrl}`}
                                                   userManagement={<UserManagement userType={agency}/>}/>}/>
                    <Route path={`/${agency}/${slotUrl}`}
                           element={<SlotDashboard navigateUrl={`/${agency}/${slotUrl}`}
                                                   slotManagement={<SlotManagement userType={agency}/>}/>}/>
                    <Route path={`/${agency}/${slotHistoryUrl}`}
                           element={
                               <SlotHistoryDashboard navigateUrl={`/${agency}/${slotHistoryUrl}`}
                                                     slotHistoryManagement={
                                                         <SlotHistoryManagement userType={agency}/>}
                               />}
                    />
                    <Route path={`/${agency}/${myPage}`} element={<AgencyMyPage/>}/>

                    <Route path={`/${adv}/${slotUrl}`}
                           element={<SlotDashboard navigateUrl={`/${adv}/${slotUrl}`}
                                                   slotManagement={<SlotManagement userType={adv}/>}/>}/>
                </Routes>
            </BrowserRouter>
        </CookiesProvider>
    );
}

export default App;
