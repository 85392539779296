// src/components/AddUserModal.js
import React from 'react';
import {useNavigate} from 'react-router-dom';
import '../../../style/AddUserModal.css';
import AddButton from '../../common/button/AddButton';
import CancelButton from '../../common/button/CancelButton';
import NormalButton from '../../common/button/NormalButton';
import OptionButton from '../../common/button/OptionButton';
import UpdateButton from '../../common/UpdateButton';

function SlotHistoryModal(props) {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const handleChange = (e) => {
        const {name, value} = e.target;
        props.setData({...props.data, [name]: value});
    };

    const historyMessageTypeCheck = (messageType) => {
        switch (messageType) {
            case '관리자 슬롯 생성':
            case '대행사 슬롯 생성':
                return <AddButton name={messageType} onClick={() => {
                }}/>;
            case '관리자 슬롯 수정':
            case '대행사 슬롯 수정':
            case '광고주 슬롯 수정':
                return <UpdateButton name={messageType} onClick={() => {
                }}/>;
            case '대행사 슬롯 기간 연장':
                return <OptionButton name={messageType} onClick={() => {
                }}/>;
            default:
                return <CancelButton name={'빈 메시지 타입'} onClick={() => {
                }}/>;
        }
    };

    const handleHistoryRender = (history) => {
        return (
            <div>
                {history.before_start_date ? '' : history.before_start_date}
            </div>
        );
    }

    return (
        <div
            className={`modal flex items-center justify-center
    ${props.isOpen ? 'block' : 'invisible'}`}
        >
            <div className="modal-dialog flex flex-col justify-between space-y-4 w-2/3">
                <div className="modal-content flex flex-col flex-1">
                    <div className="modal-header">
                        <h2>슬롯 히스토리 정보</h2>
                        <button className="close" onClick={props.onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body flex flex-1 flex-col">
                        <div className="flex flex-1">
                            <div className="flex flex-1">
                                <div className="flex flex-col flex-1 p-2 bg-gray-300 h-96 overflow-y-auto">
                                    <label className="m-2 bold">히스토리 로그</label>
                                    <div className="flex flex-1">
                                        <table className="w-full mb-4 border rounded-[20px] shadow-xl">
                                            <thead className="bg-gray-500 text-gray-200">
                                            <tr>
                                                <th className="text-center border-b-4 border-gray-300 p-2">
                                                    분류
                                                </th>
                                                <th className="text-center border-b-4 border-gray-300 p-2">
                                                    시간
                                                </th>
                                                <th className="text-center border-b-4 border-gray-300 p-2">
                                                    내용
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="bg-gray-200">
                                            {props.data.slot_log.length !== 0 ? (
                                                props.data.slot_log.map((history, index) => (
                                                    <>
                                                        <tr className={`m-2 bg-white`}>
                                                            <td className="text-center items-center border-b-4 border-gray-300 p-2">
                                                                {historyMessageTypeCheck(history.event_type)}
                                                            </td>
                                                            <td className="text-center border-b-4 border-gray-300 p-2">
                                                                <NormalButton
                                                                    name={String(history.created_at).substring(0, 19).replace('T', ' ')}
                                                                    onClick={() => {
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="border-b-4 border-gray-300 p-4 break-all">
                                                                {handleHistoryRender(history)}
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))
                                            ) : (
                                                <tr className="m-2 bg-white">
                                                    <td
                                                        colSpan="4"
                                                        className="text-center border-b-4 border-gray-300 p-4"
                                                    >
                                                        <CancelButton
                                                            name="로그가 없습니다."
                                                            onClick={() => {
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end py-4 px-2">
                            <div className="flex">
                                <NormalButton name="취소" onClick={props.onClose}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SlotHistoryModal;
