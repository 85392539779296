// src/components/Navbar.js
import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import MainTitle from './MainTitle';
import SubTitle from './SubTitle';
import axiosInstance from "../../shared/axiosInstance";
import NormalButton from "./button/NormalButton";
import OptionButton from "./button/OptionButton";
import CancelButton from "./button/CancelButton";

function Navbar() {
    const [userTicket, setUserTicket] = useState([]);
    const nickname = localStorage.getItem('nickname');
    const userType = parseInt(localStorage.getItem('user_type'));
    const token = localStorage.getItem('token');

    const showMenuBar = (() => {
        switch (userType) {
            case 3:
                return (
                    <>
                        <NavLink to="/admin/user-board">
                            <SubTitle name="유저 목록"/>
                        </NavLink>
                        <NavLink to="/admin/slot-history-board">
                            <SubTitle name="슬롯 로그 목록"/>
                        </NavLink>
                        <NavLink to="/admin/ticket-board">
                            <SubTitle name="티켓"/>
                        </NavLink>
                    </>
                );
            case 1:
                return (
                    <>
                        <NavLink to="/agency/user-board">
                            <SubTitle name="유저 목록"/>
                        </NavLink>
                        <NavLink to="/agency/slot-history-board">
                            <SubTitle name="슬롯 로그 목록"/>
                        </NavLink>
                        <NavLink to="/agency/my-page">
                            <SubTitle name="티켓 현황"/>
                        </NavLink>
                    </>
                );
            case 2:
                return (
                    <></>
                );
        }
    });

    const getTicket = (() => {
        const config = {
            headers: {
                'Content-Type': 'text/plain',
                Authorization: 'Bearer ' + token,
            },
        };

        axiosInstance
            .get('/api/v1/ticket/agency/all', config)
            .then((response) => {
                setUserTicket(response.data.agency_ticket_list);
            });
    })

    const showTicketBar = (() => {
        switch (userType) {
            case 1:
                return (
                    <>
                        <div className="flex text-sm">
                            {userTicket.length !== 0 ? (
                                userTicket.map((ticket) => (
                                    <OptionButton
                                        name={`${ticket.ticket.name} : ${ticket.count}`}
                                        onClick={() => {
                                        }}
                                    />
                                ))
                            ) : (
                                <CancelButton name="티켓이 없습니다." onClick={() => {
                                }}/>
                            )}
                        </div>
                    </>
                );
        }
    })

    useEffect(() => {
        if (userType === 1) {
            getTicket();
        }
    }, []);

    return (
        <div className="bg-gray-700 text-white p-5">
            <div className="flex flex-row justify-between">
                <div className="flex flex-col">
                    <div>
                        <img src="../../logo.png" width="15%" height="15%" alt="로고 이미지"/>
                        <MainTitle name="To-The-Moon"/>
                    </div>
                    <div className="flex m-2">
                        {showMenuBar()}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="flex justify-end gap-3">
                        <SubTitle name={nickname}/>
                        <NavLink
                            to="/"
                            onClick={() => {
                                localStorage.removeItem('token');
                                localStorage.removeItem('nickname');
                            }}
                        >
                            <SubTitle name="Logout"/>
                        </NavLink>
                    </div>
                    <div>
                        {/*<div className="flex m-1">*/}
                        {/*    {showTicketBar()}*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
