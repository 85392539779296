export const initialSelectedSlot = {
    advertiser: {},
    agency: {},
    created_at: '',
    end_date: '',
    id: '',
    keyword: '',
    platform: {},
    private_memo: '',
    public_memo: '',
    rank: '',
    slot_log: [],
    url: '',
    compare_url: '',
    userInfo: {},
}

export const initialSelectUserInfo = {
    userId: '',
    userType: '',
}