// src/components/FilterDropdown.js
import React, {useState} from 'react';

const UserDropdown = (props) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const renderOptions = () => {
        switch (props.userType) {
            case 'admin':
                return (
                    <>
                        <option value="1">대행사</option>
                        <option value="2">고객</option>
                    </>
                );
            case 'agency':
                return (
                    <>
                        <option value="2">고객</option>
                    </>
                );
        }
    };

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
        props.onFilter(value);
    };

    return (
        <div className="inline-block">
            <select
                value={selectedOption}
                onChange={handleOptionChange}
                className="border rounded py-2 px-3"
                id="selectedUserType"
            >
                {renderOptions()}
            </select>
        </div>
    );
};

export default UserDropdown;
