// src/components/AddUserModal.js
import axios from 'axios';
import $ from 'jquery';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import '../../../style/AddUserModal.css';
import AddButton from '../../common/button/AddButton';
import Dropdown from '../../common/drop-down/AgencyDropdown';
import NormalButton from '../../common/button/NormalButton';
import UserDropdown from '../../common/drop-down/UserDropdown';
import axiosInstance from "../../../shared/axiosInstance";

function AddUserModal(props) {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        nickname: '',
        password: '',
        confirmPassword: '',
    });
    const token = localStorage.getItem('token');
    const user_type = localStorage.getItem('user_type');

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const userDropdownFilter = (value) => {
        if (value === '2') {
            $('#agencyDropdown').removeClass('hidden');
        } else {
            $('#agencyDropdown').addClass('hidden');
        }
    };

    const handleAddUser = () => {
        if (
            formData.name &&
            formData.nickname &&
            formData.password &&
            formData.confirmPassword
        ) {
            addUser();
            props.onClose();
        }
    };

    const addUser = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };

            const data = {
                user_type: 2,
                name: formData.name,
                nickname: formData.nickname,
                password: formData.password,
                confirm_password: formData.confirmPassword,
                agency_user_id: parseInt(localStorage.getItem('user_id'))
            };

            axiosInstance
                .post('/api/v1/accounts/create', data, config)
                .then(() => {
                    window.location.reload();
                    props.onClose();
                })
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }
            console.log(error);
            props.onClose();
        }
    };

    return (
        <div
            className={`modal flex items-center justify-center
    ${props.isOpen ? 'block' : 'invisible'}`}
        >
            <div className="modal-dialog" style={{width: '40%'}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>유저 추가</h2>
                        <button className="close" onClick={props.onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="p-2 mb-4 bg-gray-100">
                            <label className="flex block mb-2">이름</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                placeholder="이름을 입력하세요"
                                className="border rounded w-full py-2 px-3"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="p-2 mb-4 bg-gray-100">
                            <label className="m-2">ID</label>
                            <input
                                type="text"
                                id="nickname"
                                name="nickname"
                                value={formData.nickname}
                                placeholder="아이디를 입력하세요."
                                className="border rounded w-full py-2 px-3"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="p-2 mb-4 bg-gray-100">
                            <label className="m-2">PW</label>
                            <input
                                type="text"
                                id="password"
                                name="password"
                                value={formData.password}
                                placeholder="비밀번호를 입력하세요."
                                className="border rounded w-full py-2 px-3"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="p-2 mb-4 bg-gray-100">
                            <label className="m-2">PW 확인</label>
                            <input
                                type="text"
                                id="confirmPassword"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                placeholder="비밀번호를 재입력하세요."
                                className="border rounded w-full py-2 px-3"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div class="flex justify-end">
                        <AddButton name="추가" onClick={handleAddUser}/>
                        <NormalButton name="취소" onClick={props.onClose}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddUserModal;
