import React, {Component} from 'react';

class UpdateButton extends Component {
    render() {
        const name = this.props.name;

        return (
            <button
                className="flex bg-blue-500 hover:bg-blue-600 active:bg-blue-700 text-white py-1 px-2 rounded m-2"
                onClick={this.props.onClick}
                onMouseOver={() => {
                    this.props.onMouseOver && this.props.onMouseOver();
                }}
            >
                {name}
            </button>
        );
    }
}

export default UpdateButton;
