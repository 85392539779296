// src/components/AddUserModal.js
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import '../../../style/AddUserModal.css';
import CancelButton from '../../common/button/CancelButton';
import NormalButton from '../../common/button/NormalButton';
import UpdateButton from '../../common/UpdateButton';
import axiosInstance from "../../../shared/axiosInstance";

function UserModal(props) {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const handleChange = (e) => {
        const {name, value} = e.target;
        props.setData({...props.data, [name]: value});
    };

    const updateUser = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };

            axiosInstance
                .patch(`/api/v1/accounts/${props.data.id}`, props.data, config)
                .then(() => {
                    window.location.reload();
                    props.onClose();
                });
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }

            console.log(error);
            props.onClose();
        }
    };

    const deleteUser = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };

            axiosInstance
                .patch(`/api/v1/accounts/${props.data.id}/inactive`, config)
                .then(() => {
                    window.location.reload();
                    props.onClose();
                });
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }
            console.log(error);
            props.onClose();
        }
    };

    return (
        <div
            className={`modal flex items-center justify-center
            ${props.isOpen ? 'block' : 'invisible'}`}
        >
            <div className="modal-dialog" style={{width: '40%'}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>유저 정보</h2>
                        <button className="close" onClick={props.onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="p-2 mb-4 bg-gray-100">
                            <label className="flex block mb-2">이름</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={props.data.name}
                                placeholder="이름을 입력하세요"
                                className="border rounded w-full py-2 px-3"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="p-2 mb-4 bg-gray-100">
                            <label className="m-2">ID</label>
                            <input
                                type="text"
                                id="nickname"
                                name="nickname"
                                value={props.data.nickname}
                                placeholder="아이디를 입력하세요."
                                className="border rounded w-full py-2 px-3"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="p-2 mb-4 bg-gray-100">
                            <label className="m-2">PW</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={props.data.password}
                                placeholder="비밀번호를 입력하세요."
                                className="border rounded w-full py-2 px-3"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="p-2 mb-4 bg-gray-100">
                            <label className="m-2">PW 확인</label>
                            <input
                                type="password"
                                id="confirm_password"
                                name="confirm_password"
                                value={props.data.confirmPassword}
                                placeholder="비밀번호를 재입력하세요."
                                className="border rounded w-full py-2 px-3"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <div class="flex">
                            <UpdateButton name="정보 수정" onClick={updateUser}/>
                        </div>
                        <div class="flex">
                            <CancelButton name="비활성화" onClick={deleteUser}/>
                        </div>
                        <div class="flex">
                            <NormalButton name="취소" onClick={props.onClose}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserModal;
