// src/components/FilterDropdown.js
import axios from 'axios';
import {React, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axiosInstance from "../../../shared/axiosInstance";
import CancelButton from "../button/CancelButton";

const PlatformDropdown = ({userId, onChange, onSelect}) => {
    const navigate = useNavigate();
    const [platforms, setPlatforms] = useState(null);
    const [selectedOption, setSelectedOption] = useState(-1);
    const token = localStorage.getItem('token');

    const getPlatform = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };
            axiosInstance
                .get('/api/v1/platform/all', config)
                .then((response) => {
                    setPlatforms(response.data.platform_list);
                    // if (response.data.platform_list.length != 0) {
                    //     onChange(userId, response.data.platform_list[0].id);
                    // }
                })
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }
            console.log(error);
        }
    };

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
        onSelect(value);
        onChange(userId, value);
    };

    useEffect(() => {
        getPlatform();
    }, []);

    return (
        <div className="inline-block">
            <label className="m-2">플랫폼</label>
            <select
                value={selectedOption}
                onChange={handleOptionChange}
                className="border rounded py-2 px-3"
                id="selectedPlatformType"
            >
                <option value={-1}>선택</option>
                {platforms != null ? (
                    platforms.map((platform) => (
                        <option value={platform.id}>
                            {platform.name}
                        </option>
                    ))
                ) : (
                    <option><CancelButton name="유저가 없습니다."/></option>
                )}
                <option></option>
            </select>
        </div>
    );
};

export default PlatformDropdown;
