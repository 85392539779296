import React from 'react';
import {Navigate} from 'react-router-dom';
import Navbar from '../../common/Navbar';

function UserDashboard(props) {
    const {navigateUrl, userManagement} = props;
    const token = localStorage.getItem('token');

    return (
        <div className="App flex">
            {!token ? <Navigate to="/"/> : <Navigate to={navigateUrl}/>}

            <div className="flex-1">
                <Navbar/>

                <div className="bg-gray-200 min-h-screen p-4">
                    {userManagement}
                </div>
            </div>
        </div>
    );
}

export default UserDashboard;
