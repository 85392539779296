// src/components/FilterDropdown.js
import React, {useState} from 'react';

const SlotFilterDropdown = ({onFilter, userType}) => {
    const [selectedOption, setSelectedOption] = useState(0);

    const renderOptions = () => {
        return (
            <>
                <option value="0">모두</option>
                <option value="1">관리자 슬롯 생성</option>
                <option value="2">관리자 슬롯 수정</option>
                <option value="3">대행사 슬롯 생성</option>
                <option value="4">대행사 슬롯 수정</option>
                <option value="5">대행사 기간 연장</option>
                <option value="6">광고주 슬롯 수정</option>
                <option value="7">관리자 기간 연장</option>
                <option value="8">관리자 슬롯 비활성화</option>
                <option value="9">대행사 슬롯 비활성화</option>
            </>
        );
    };

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
        onFilter(value);
    };

    return (
        <div className="inline-block flex items-center">
            <select
                value={selectedOption}
                onChange={handleOptionChange}
                className="border rounded py-2 px-3"
            >
                {renderOptions()}
            </select>
        </div>
    );
};

export default SlotFilterDropdown;
