import React, {useEffect, useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {useNavigate} from 'react-router-dom';
import '../../../style/AddUserModal.css';
import AddButton from '../../common/button/AddButton';
import CancelButton from '../../common/button/CancelButton';
import NormalButton from '../../common/button/NormalButton';
import UpdateButton from '../../common/UpdateButton';
import axiosInstance from "../../../shared/axiosInstance";
import {TextField} from "../Editor/TextField";
import {TextArea} from "../Editor/TextArea";
import {DatePickerInput} from "../Editor/DatePickerInput";
import {ExtraTextField} from "../Editor/ExtraTextField";

function SlotModal(props) {
    const navigate = useNavigate();
    const {
        extraFieldNames,
        data,
        setData,
        setLabel,
        isOpen,
        onClose,
        userType,
        setErrorMessage,
        setIsErrorModalOpen
    } = props;
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const token = localStorage.getItem('token');

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    };

    const updateData = async () => {
        const config = {
            headers: {
                'Content-Type': 'text/plain',
                'Authorization': 'Bearer ' + token,
            },
        };

        const formData = {
            ...data,
            start_date: `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`,
            end_date: `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}`,
            keyword: data.keyword != null ? data.keyword : "",
            url: data.url != null ? data.url : "",
            compare_url: data.compare_url != null ? data.compare_url : "",
            public_memo: data.public_memo != null ? data.public_memo : "",
            private_memo: data.private_memo != null ? data.private_memo : "",
        };

        const userId = data.userId;
        const slotId = data.id;

        try {
            switch (userType) {
                case 'admin':
                    await axiosInstance.patch(`/api/v1/accounts/${userId}/slots/${slotId}/admin/info`, formData, config);
                    break;
                case 'agency':
                    await axiosInstance.patch(`/api/v1/accounts/${userId}/slots/${slotId}/agency/info`, formData, config);
                    break;
                case 'adv':
                    await axiosInstance.patch(`/api/v1/accounts/${userId}/slots/${slotId}/adv/info`, formData, config);
                    break;
            }

            window.location.reload();
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }
            console.log(error);
            setErrorMessage(error.response.data);
            setIsErrorModalOpen(true);
            onClose();
        }
    };

    const deleteData = () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };

            const userId = data.userId;
            const slotId = data.id;

            axiosInstance
                .delete(`/api/v1/accounts/${userId}/slots/${slotId}/admin`, config)
                .then(() => {
                    window.location.reload();
                    onClose();
                })
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }
            console.log(error);
            onClose();
        }
    }

    const inActive = () => {
        const config = {
            headers: {
                'Content-Type': 'text/plain',
                'Authorization': 'Bearer ' + token,
            },
        };


        const userId = localStorage.getItem('slotUserId');
        const slotId = data.id;

        axiosInstance
            .delete(`/api/v1/accounts/${userId}/slots/${slotId}/inactive`, config)
            .then((response) => {
                window.location.reload();
                onClose();
            })
            .catch((error) => {
                if (error.response.statusText === 'Unauthorized') {
                    navigate('/');
                    localStorage.removeItem('token');
                    localStorage.removeItem('nickname');
                }
                console.log(error);
            })
    }

    useEffect(() => {
        if (isOpen && data) {
            setStartDate(new Date(data.start_date));
            setEndDate(new Date(data.end_date));
        }
    }, [isOpen, data]);

    return (
        <div
            className={`modal flex items-center justify-center
            ${isOpen ? 'block' : 'invisible'}`}>
            <div className="modal-dialog w-1/2 mx-auto" style={{width: '70%'}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1>슬롯 정보</h1>
                        <button className="close" onClick={onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="grid grid-cols-2 gap-4">
                            {/* 1 */}
                            <div className="flex flex-col space-y-4">
                                <div className="flex flex-1 space-x-2">
                                    <TextField labelName={"대행사"} readOnly={true} id={"agencyName"} name={"agencyName"}
                                               value={data.agency.name}/>
                                    <TextField labelName={"광고주"} readOnly={true} id={"advertiserName"}
                                               name={"advertiserName"}
                                               value={data.advertiser.name}/>
                                </div>
                                <div className="flex space-x-2 flex-1">
                                    <TextField labelName={"플랫폼"} readOnly={true} id={"platformName"}
                                               name={"platformName"}
                                               value={data.platform.name}/>
                                    <TextField labelName={"랭크"} readOnly={true} id={"rank"} name={"rank"}
                                               value={data.rank}/>
                                </div>
                            </div>

                            {/* 2 */}
                            <div className="flex flex-col space-y-2">
                                <TextField labelName={"키워드"} id={"keyword"} name={"keyword"} value={data.keyword}
                                           onChange={handleChange}/>
                                <TextField labelName={"상품링크"} id={"url"} name={"url"} value={data.url}
                                           onChange={handleChange}/>
                                <TextField labelName={"가격 비교 상품 링크"} id={"compare_url"} name={"compare_url"}
                                           value={data.compare_url} onChange={handleChange}/>
                                <div className="flex space-x-2 flex-1">
                                    <DatePickerInput labelName={"시작일"} id={"createdAt"} date={startDate}
                                                     setDate={setStartDate}/>
                                    <DatePickerInput labelName={"종료일"} id={"endDate"} date={endDate}
                                                     setDate={setEndDate}/>
                                </div>
                            </div>

                            {/* 3 */}
                            <TextArea labelName={"공개 메모"} rows={4} id={"public_memo"} name={"public_memo"}
                                      value={data.public_memo} onChange={handleChange}/>
                            {/* 4 */}
                            <TextArea labelName={"비공개 메모"} rows={4} id={"private_memo"} name={"private_memo"}
                                      value={data.private_memo} onChange={handleChange}/>

                            <div className="flex flex-col space-y-2">
                                <TextField labelName={extraFieldNames?.extra_field_1_name ?? ''}
                                           id={"extra_field_1"}
                                           name={"extra_field_1"} value={data.extra_field_1 ?? ''}
                                           onChange={handleChange}/>
                                <TextField labelName={extraFieldNames?.extra_field_3_name ?? ''}
                                           id={"extra_field_3"}
                                           name={"extra_field_3"} value={data.extra_field_3 ?? ''}
                                           onChange={handleChange}/>
                                <TextField labelName={extraFieldNames?.extra_field_5_name ?? ''}
                                           id={"extra_field_5"}
                                           name={"extra_field_5"} value={data.extra_field_5 ?? ''}
                                           onChange={handleChange}/>
                            </div>
                            <div className="flex flex-col space-y-2">
                                <TextField labelName={extraFieldNames?.extra_field_2_name ?? ''}
                                           id={"extra_field_2"}
                                           name={"extra_field_2"} value={data.extra_field_2 ?? ''}
                                           onChange={handleChange}/>
                                <TextField labelName={extraFieldNames?.extra_field_4_name ?? ''}
                                           id={"extra_field_4"}
                                           name={"extra_field_4"} value={data.extra_field_4 ?? ''}
                                           onChange={handleChange}/>
                                <TextField labelName={extraFieldNames?.extra_field_6_name ?? ''}
                                           id={"extra_field_6"}
                                           name={"extra_field_6"} value={data.extra_field_6 ?? ''}
                                           onChange={handleChange}/>
                            </div>

                            {/*<div className="flex flex-col space-y-2">*/}
                            {/*    <ExtraTextField labelName={"extra_field_1_name"}*/}
                            {/*                    labelValue={extraFieldNames?.extra_field_1_name ?? ''}*/}
                            {/*                    labelOnChange={handleLabelChange}*/}
                            {/*                    id={"extra_filed_1"}*/}
                            {/*                    name={"extra_field_1"} value={data.extra_field_1}*/}
                            {/*                    onChange={handleChange}/>*/}
                            {/*    <ExtraTextField labelName={"extra_field_3_name"}*/}
                            {/*                    labelValue={extraFieldNames?.extra_field_3_name ?? ''}*/}
                            {/*                    labelOnChange={handleLabelChange}*/}
                            {/*                    id={"extra_filed_3"}*/}
                            {/*                    name={"extra_field_3"} value={data.extra_field_3}*/}
                            {/*                    onChange={handleChange}/>*/}
                            {/*    <ExtraTextField labelName={"extra_field_5_name"}*/}
                            {/*                    labelValue={extraFieldNames?.extra_field_5_name ?? ''}*/}
                            {/*                    labelOnChange={handleLabelChange}*/}
                            {/*                    id={"extra_filed_5"}*/}
                            {/*                    name={"extra_field_5"} value={data.extra_field_5}*/}
                            {/*                    onChange={handleChange}/>*/}
                            {/*</div>*/}
                            {/*<div className="flex flex-col space-y-2">*/}
                            {/*    <ExtraTextField labelName={"extra_field_2_name"}*/}
                            {/*                    labelValue={extraFieldNames?.extra_field_2_name ?? ''}*/}
                            {/*                    labelOnChange={handleLabelChange}*/}
                            {/*                    id={"extra_filed_2"}*/}
                            {/*                    name={"extra_field_2"} value={data.extra_field_2}*/}
                            {/*                    onChange={handleChange}/>*/}
                            {/*    <ExtraTextField labelName={"extra_field_4_name"}*/}
                            {/*                    labelValue={extraFieldNames?.extra_field_4_name ?? ''}*/}
                            {/*                    labelOnChange={handleLabelChange}*/}
                            {/*                    id={"extra_filed_4"}*/}
                            {/*                    name={"extra_field_4"} value={data.extra_field_4}*/}
                            {/*                    onChange={handleChange}/>*/}
                            {/*    <ExtraTextField labelName={"extra_field_6_name"}*/}
                            {/*                    labelValue={extraFieldNames?.extra_field_6_name ?? ''}*/}
                            {/*                    labelOnChange={handleLabelChange}*/}
                            {/*                    id={"extra_filed_6"}*/}
                            {/*                    name={"extra_field_6"} value={data.extra_field_6}*/}
                            {/*                    onChange={handleChange}/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <div class="flex">
                            <UpdateButton name="정보 수정" onClick={updateData}/>
                        </div>
                        {userType === 'admin' ?
                            (<>
                                    <div className="flex">
                                        <CancelButton name="비활성화" onClick={inActive}/>
                                    </div>
                                    <div className="flex">
                                        <CancelButton name="삭제" onClick={deleteData}/>
                                    </div>
                                </>
                            ) : (<></>)
                        }
                        <div class="flex">
                            <NormalButton name="취소" onClick={onClose}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SlotModal;
