// src/components/FilterDropdown.js
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import axiosInstance from "../../../shared/axiosInstance";

const AgencyDropdown = () => {
    const [users, setUsers] = useState(null);
    const [selectedOption, setSelectedOption] = useState('all'); // 초기 선택: 모두
    const token = localStorage.getItem('token');

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
    };

    const getPlatform = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };
            const response = await axiosInstance.get('/api/v1/accounts/agency/all', config);
            setUsers(response.data.user_list);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getPlatform();
    }, []);

    return (
        <div className="inline-block">
            <select
                value={selectedOption}
                onChange={handleOptionChange}
                className="border rounded py-2 px-3"
                id="selectedAgency"
            >
                {users != null ? (
                    users.map((user) => <option value={user.id}>{user.name}</option>)
                ) : (
                    <option>null</option>
                )}
                <option></option>
            </select>
        </div>
    );
};

export default AgencyDropdown;
