import React, {Component} from 'react';
import '../../style/font.css';

class MainTitle extends Component {
    render() {
        const name = this.props.name;

        return <h1 className="underline">{name}</h1>;
    }
}

export default MainTitle;
