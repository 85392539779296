import NormalButton from "../button/NormalButton";
import {React} from "react";
import OptionButton from "../button/OptionButton";
import AddButton from "../button/AddButton";
import UpdateButton from "../UpdateButton";
import CancelButton from "../button/CancelButton";
import axiosInstance from "../../../shared/axiosInstance";

export const renderPageNumbers = (page, setPage, pageSize, setPageSize, totalPage) => {
    const pageNumbers = [];
    const maxPagesToShow = 10;
    const maxAdjacentPages = 5;

    if (totalPage <= maxPagesToShow) {
        for (let i = 1; i <= totalPage; i++) {
            pageNumbers.push(
                <NormalButton
                    key={i}
                    name={i}
                    onClick={() => setPage(i)}
                    enable={page === i ? false : true}
                />
            );
        }
    } else {
        let startPage = Math.max(1, page - maxAdjacentPages);
        let endPage = Math.min(totalPage, startPage + maxPagesToShow - 1);

        if (endPage - startPage < maxPagesToShow - 1) {
            startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }

        if (startPage > 1) {
            pageNumbers.push(
                <NormalButton
                    key={1}
                    name={1}
                    onClick={() => setPage(1)}
                    enable={page !== 1}
                />
            );
            if (startPage > 2) {
                pageNumbers.push(
                    <span key="startEllipsis">. . .</span>
                );
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <NormalButton
                    key={i}
                    name={i}
                    onClick={() => setPage(i)}
                    enable={page === i ? false : true}
                />
            );
        }

        if (endPage < totalPage) {
            if (endPage < totalPage - 1) {
                pageNumbers.push(
                    <span key="endEllipsis">...</span>
                );
            }
            pageNumbers.push(
                <NormalButton
                    key={totalPage}
                    name={totalPage}
                    onClick={() => setPage(totalPage)}
                    enable={page !== totalPage}
                />
            );
        }
    }

    return pageNumbers;
};

export const showStatus = (status) => {
    switch (status) {
        case "WAITED":
            return <OptionButton name={'대기중'}/>
        case 'INPROGRESS':
            return <AddButton name={'진행중'}/>
        case 'COMPLETED':
            return <UpdateButton name={'완료됨'}/>
        case 'INACTIVE':
            return <CancelButton name={'비활성화'}/>
    }
}

export async function getSlotConfig(token, userId) {
    try {
        const config = {
            headers: {
                'Content-Type': 'text/plain',
                Authorization: 'Bearer ' + token,
            },
        };

        const response = await axiosInstance.get(`/api/v1/accounts/${userId}/slots/config`, config);
        return response.data;
    } catch (error) {
        console.log(error);
        if (error.response.statusText === 'Unauthorized') {
            localStorage.removeItem('token');
            localStorage.removeItem('nickname');
        }
    }
}

export const showUserType = (type) => {
    switch (type) {
        case 1:
            return <button
                className="flex bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 text-white py-1 px-2 rounded m-2"
                onClick={() => {
                }}
            >
                {'대행사'}
            </button>
        case 2:
            return <button
                className="flex bg-green-400 hover:bg-green-600 active:bg-green-700 text-white py-1 px-2 rounded m-2"
                onClick={() => {
                }}
            >
                {'광고주'}
            </button>
    }
}
