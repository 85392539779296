// src/components/FilterDropdown.js
import React, {useEffect, useState} from 'react';
import axiosInstance from "../../../shared/axiosInstance";
import {useNavigate} from "react-router-dom";
import ErrorModal from "../ErrorModal";

const SlotFilterDropdown = ({onFilter, userType}) => {
    const navigate = useNavigate();
    const [userList, setUserList] = useState([]);
    const [selectedOption, setSelectedOption] = useState(0);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const token = localStorage.getItem('token');

    const openModal = () => {
        setIsErrorModalOpen(true);
    };

    const closeModal = () => {
        setIsErrorModalOpen(false);
    };

    const getUsers = () => {
        const config = {
            headers: {
                'Content-Type': 'text/plain',
                Authorization: 'Bearer ' + token,
            },
        };

        axiosInstance
            .get(`/api/v1/accounts/ag-adv/all`, config)
            .then((response) => response.data)
            .then((data) => {
                setUserList(data.user_list);
            })
            .catch((error) => {
                console.log(error);
                if (error.response.statusText === 'Unauthorized') {
                    navigate('/');
                    localStorage.removeItem('token');
                    localStorage.removeItem('nickname');
                }
                setErrorMessage(error.response.data);
                openModal();
            });
    }

    const renderOptions = () => {
        return (
            <>

            </>
        );
    };

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
        onFilter(value);
    };

    useEffect(() => {
        getUsers();
    }, [])

    return (
        <div className="inline-block flex items-center">
            <select
                value={selectedOption}
                onChange={handleOptionChange}
                className="border rounded py-2 px-3"
            >
                <option value="0">모두</option>
                {userList.length !== 0 ? (
                    userList.map((user, index) => (
                        <option value={user.id}>{user.nickname}</option>
                    ))) : <></>}
            </select>
            <ErrorModal
                isOpen={isErrorModalOpen === true}
                onClose={closeModal}
                message={errorMessage}
            />
        </div>
    );
};

export default SlotFilterDropdown;
