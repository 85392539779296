// src/components/AddUserModal.js
import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useNavigate} from 'react-router-dom';
import axiosInstance from '../../../shared/axiosInstance';
import '../../../style/AddUserModal.css';
import AddButton from '../../common/button/AddButton';
import AdvertiserDropdown from '../../common/drop-down/AdvertiserDropdown';
import AgencyDropdown from '../../common/drop-down/AgencyDropdown';
import CancelButton from '../../common/button/CancelButton';
import NormalButton from '../../common/button/NormalButton';
import PlatformDropdown from '../../common/drop-down/PlatformDropdown';
import {TextField} from "../Editor/TextField";
import {DatePickerInput} from "../Editor/DatePickerInput";
import ErrorModal from "../ErrorModal";

function AddSlotModal(props) {
    const navigate = useNavigate();
    const {extraFieldNames, data, setData, setLabel, isOpen, onClose, userType} = props;
    const [userTicket, setUserTicket] = useState([]);
    const [selectedRow, setSelectedRow] = useState(-1);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [formData, setFormData] = useState({
        slot_cnt: '',
        keyword: '',
        url: '',
        compare_url: '',
        private_memo: '',
        public_memo: '',
        extra_field_1: '',
        extra_field_2: '',
        extra_field_3: '',
        extra_field_4: '',
        extra_field_5: '',
        extra_field_6: '',
    });
    const token = localStorage.getItem('token');
    // const [selectTicket, setSelectTicket] = useState([]);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const openModal = () => {
        setIsErrorModalOpen(true);
    };

    const closeModal = () => {
        setIsErrorModalOpen(false);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleAddSlot = () => {
        addSlot();
        onClose();
    };

    const addSlot = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };

            let data = formData;
            data['start_date'] = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`;
            data['end_date'] = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}`;
            data['platform_id'] = parseInt(document.getElementById('selectedPlatformType').value);
            data['slot_cnt'] = parseInt(data['slot_cnt']);

            switch (parseInt(props.data.userType)) {
                case 1:
                    data['agency_id'] = parseInt(props.data.userId);
                    data['advertiser_id'] = parseInt(document.getElementById('selectedAdvertiser').value);
                    break;
                case 2:
                    data['agency_id'] = parseInt(localStorage.getItem('agency_user_id'));
                    data['advertiser_id'] = parseInt(props.data.userId);
                    break;
            }

            await axiosInstance.post(`/api/v1/accounts/${props.data.userId}/slots/admin/create`, data, config)
            window.location.reload();
            props.onClose();
        } catch (error) {
            if (error.response === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }

            props.onClose();
            console.log(error);
        }
    };

    const showTargetDropdown = () => {
        switch (parseInt(props.data.userType)) {
            case 1:
                return (
                    <>
                        <label className="m-2">광고주</label>
                        <AdvertiserDropdown/>
                    </>
                );
        }
    };

    const getUserTicket = async (userId, platformId) => {
        try {
            if (userId === '' || platformId === '') {
                return;
            }

            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };

            const response = await axiosInstance.get(
                `/api/v1/accounts/${userId}/platform/${platformId}/ticket/detail`,
                config
            );
            setUserTicket(response.data.user_ticket_list);
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }
            console.log(error);
        }
    };

    const handleRowClick = (index) => {
        if (selectedRow === index) {
            setSelectedRow(-1);
        } else {
            setSelectedRow(index);
        }
    };

    const handleCreateDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    return (
        <div
            className={`modal flex items-center justify-center
    ${props.isOpen ? 'block' : 'invisible'}`}
        >
            <div className="modal-dialog w-1/2 mx-auto" style={{width: '50%'}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>슬롯 추가</h2>
                        <button className="close" onClick={props.onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body flex flex-1">
                        <div className="flex flex-col flex-1 space-y-4">
                            {/* 대행사와 광고주 선택 */}
                            <div className="grid grid-cols-2 gap-4">
                                <div className="flex flex-col justify-around">
                                    {showTargetDropdown()}
                                    <DatePickerInput labelName={"시작일"} id={"createdAt"} date={startDate}
                                                     setDate={handleCreateDateChange}/>
                                    <DatePickerInput labelName={"종료일"} id={"endDate"} date={endDate}
                                                     setDate={handleEndDateChange}/>
                                    <div className={"flex"}>
                                        <div className="p-2 bg-gray-100">
                                            <PlatformDropdown
                                                userId={props.data.userId}
                                                onChange={getUserTicket}
                                            />
                                        </div>
                                        <div className="p-2 bg-gray-100">
                                            <TextField labelName={"슬롯 개수"} id={"slot_cnt"} name={"slot_cnt"}
                                                       value={formData.slot_cnt} onChange={handleChange}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-2 mb-4 bg-gray-100">
                                <TextField labelName={"키워드"} id={"keyword"} name={"keyword"}
                                           value={formData.keyword} onChange={handleChange}/>
                            </div>

                            {/* url */}
                            <div className="p-2 mb-4 bg-gray-100">
                                <TextField labelName={"url"} id={"url"} name={"url"}
                                           value={formData.url} onChange={handleChange}/>
                            </div>

                            <div className="p-2 mb-4 bg-gray-100">
                                <TextField labelName={"가격 비교 url"} id={"compare_url"} name={"compare_url"}
                                           value={formData.compare_url} onChange={handleChange}/>
                            </div>
                            <div className="p-2 mb-4 bg-gray-100">
                                <TextField labelName={"공개 메모"} id={"public_memo"} name={"public_memo"}
                                           value={formData.public_memo} onChange={handleChange}/>
                            </div>
                            <div className="p-2 mb-4 bg-gray-100">
                                <TextField labelName={"비공개 메모"} id={"private_memo"} name={"private_memo"}
                                           value={formData.private_memo} onChange={handleChange}/>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="flex flex-col space-y-2">
                                    <TextField labelName={extraFieldNames?.extra_field_1_name ?? ''}
                                               id={"extra_filed_1"}
                                               name={"extra_field_1"} value={formData.extra_field_1 ?? ''}
                                               onChange={handleChange}/>
                                    <TextField labelName={extraFieldNames?.extra_field_3_name ?? ''}
                                               id={"extra_filed_3"}
                                               name={"extra_field_3"} value={formData.extra_field_3 ?? ''}
                                               onChange={handleChange}/>
                                    <TextField labelName={extraFieldNames?.extra_field_5_name ?? ''}
                                               id={"extra_filed_5"}
                                               name={"extra_field_5"} value={formData.extra_field_5 ?? ''}
                                               onChange={handleChange}/>
                                </div>
                                <div className="flex flex-col space-y-2">
                                    <TextField labelName={extraFieldNames?.extra_field_2_name ?? ''}
                                               id={"extra_filed_2"}
                                               name={"extra_field_2"} value={formData.extra_field_2 ?? ''}
                                               onChange={handleChange}/>
                                    <TextField labelName={extraFieldNames?.extra_field_4_name ?? ''}
                                               id={"extra_filed_4"}
                                               name={"extra_field_4"} value={formData.extra_field_4 ?? ''}
                                               onChange={handleChange}/>
                                    <TextField labelName={extraFieldNames?.extra_field_6_name ?? ''}
                                               id={"extra_filed_6"}
                                               name={"extra_field_6"} value={formData.extra_field_6 ?? ''}
                                               onChange={handleChange}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <AddButton name="추가" onClick={handleAddSlot}/>
                        <NormalButton name="취소" onClick={props.onClose}/>
                    </div>
                </div>
                <ErrorModal
                    isOpen={isErrorModalOpen === true}
                    onClose={closeModal}
                    message={errorMessage}
                />
            </div>
        </div>
    );
}

export default AddSlotModal;
