import React, {Component} from 'react';

class NormalButton extends Component {
    render() {
        const name = this.props.name;

        return (
            <button
                className={`bg-gray-100 border border-gray-300 py-1 px-2 rounded m-2
                ${this.props.enable !== false ? 'hover:bg-gray-200 active:bg-gray-300' : 'opacity-50 '}`}
                onClick={this.props.onClick}
                disabled={this.props.enable === false}
            >
                {name}
            </button>
        );
    }
}

export default NormalButton;
