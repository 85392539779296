import UpdateButton from "../UpdateButton";
import OptionButton from "../button/OptionButton";
import AddButton from "../button/AddButton";
import {React} from "react";

export function ManagementOptionBar(props) {
    const {
        userType,
        userSlots,
        checkedSlots,
        setSlotData,
        setIsAllSlotModalOpen,
        setSelectUserInfo,
        setIsAddSlotPeriodModalOpen,
        setAddSlotModalExcelOpen,
        setAddSlotModalOpen
    } = props;

    const generateManagementOptionBar = (userType) => {
        switch (userType) {
            case 'admin':
            case 'agency':
                return <>
                    <UpdateButton
                        name="슬롯 일괄 수정"
                        onClick={() => {
                            let data = userSlots.filter(elem => elem.id === checkedSlots[0]);
                            setSlotData(data[0]);
                            setIsAllSlotModalOpen(true);
                        }}
                    />
                    <OptionButton
                        name="슬롯 기간 연장"
                        onClick={() => {
                            let data = {
                                userId: localStorage.getItem('slotUserId'),
                                userType: localStorage.getItem('slotUserType'),
                            };
                            setSelectUserInfo(data);
                            setIsAddSlotPeriodModalOpen(true);
                        }}
                    />
                    <AddButton
                        name="슬롯 추가 (엑셀)"
                        onClick={() => {
                            let data = {
                                userId: localStorage.getItem('slotUserId'),
                                userType: localStorage.getItem('slotUserType'),
                            };
                            setSelectUserInfo(data);
                            setAddSlotModalExcelOpen(true);
                        }}
                    />
                    <AddButton
                        name="슬롯 추가"
                        onClick={() => {
                            let data = {
                                userId: localStorage.getItem('slotUserId'),
                                userType: localStorage.getItem('slotUserType'),
                            };
                            setSelectUserInfo(data);
                            setAddSlotModalOpen(true);
                        }}
                    />
                </>;

            case 'adv':
                return <>
                    <UpdateButton
                        name="슬롯 일괄 수정"
                        onClick={() => {
                            let data = userSlots.filter(elem => elem.id === checkedSlots[0]);
                            setSlotData(data[0]);
                            setIsAllSlotModalOpen(true);
                        }}
                    />
                </>;
        }
    }

    return (
        <div className="flex">
            {generateManagementOptionBar(userType)}
        </div>
    );
}