// src/components/FilterDropdown.js
import React, {useState} from 'react';

const PageSizeFilterDropdown = ({onFilter, userType, setPage, pageSize, setPageSize}) => {
    const renderOptions = () => {
        return (
            <>
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
            </>
        );
    };

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setPageSize(value);
        setPage(1);
    };

    return (
        <div className="inline-block flex items-center">
            <select
                value={pageSize}
                onChange={handleOptionChange}
                className="border rounded py-2 px-3"
            >
                {renderOptions()}
            </select>
        </div>
    );
};

export default PageSizeFilterDropdown;
