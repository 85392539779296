// src/components/Dashboard.js
import React from 'react';
import {Navigate} from 'react-router-dom';
import Navbar from '../../common/Navbar';
import TicketManagement from './TicketManagement';

function UserDashboard() {
    const token = localStorage.getItem('token');

    return (
        <div className="App flex">
            {!token ? <Navigate to="/"/> : <Navigate to="/admin/ticket-board"/>}

            <div className="flex-1">
                <Navbar/>

                <div className="bg-gray-200 min-h-screen p-4">
                    <TicketManagement/>
                </div>
            </div>
        </div>
    );
}

export default UserDashboard;
