import PageSizeFilterDropdown from "../drop-down/PageSizeFilterDropdown";
import SlotFilterDropdown from "../drop-down/SlotFilterDropdown";
import SlotSortFilterDropdown from "../drop-down/SlotSortFilterDropdown";
import UpdateButton from "../UpdateButton";

export function ManagementSearchBar(props) {
    const {
        setPage,
        pageSize,
        setPageSize,
        statusOption,
        setStatusOption,
        sortOption,
        setSortOption,
        handleSearch,
        getData,
    } = props;

    return (
        <div className="flex space-x-4 flex-1">
            <label className="flex space-x-2 text-gray-600">
                <div className='flex items-center'>페이지당</div>
                <PageSizeFilterDropdown
                    setPage={setPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}/>
            </label>
            <label className="flex space-x-2 text-gray-600">
                <div className='flex items-center'>필터</div>
                <SlotFilterDropdown
                    statusOption={statusOption}
                    setStatusOption={setStatusOption}/>
            </label>
            <label className="flex space-x-2 text-gray-600">
                <div className='flex items-center'>정렬</div>
                <SlotSortFilterDropdown
                    sortOption={sortOption}
                    setSortOption={setSortOption}/>
            </label>
            <label className="flex space-x-2 text-gray-600 flex-1 text-center">
                <div className='flex items-center'>검색</div>
                <input
                    type="text"
                    id="searched"
                    name="searched"
                    placeholder="검색어를 입력하세요"
                    className="border rounded w-[50%] py-2 px-3"
                    onKeyDown={handleSearch}
                />
                <UpdateButton name="검색" onClick={getData}/>
            </label>
        </div>
    );
}