import NormalButton from "../button/NormalButton";
import UpdateButton from "../UpdateButton";
import CancelButton from "../button/CancelButton";
import {React} from "react";
import {showStatus} from "../util/Util";

export function DataTable(props) {
    const {
        userType,
        userSlots,
        handleShowHistoryLog,
        handleShowRankLog,
        setSelectedSlot,
        setSlotModalOpen,
        checkedSlots,
        setCheckedSlots
    } = props;
    const handleCheckboxChange = (event, slotId) => {
        if (slotId === 'selectAll') {
            if (event.target.checked) {
                setCheckedSlots(userSlots.map((userSlot) => userSlot.id));
            } else {
                setCheckedSlots([]);
            }
        } else {
            if (event.target.checked) {
                setCheckedSlots((prevCheckedSlots) => [...prevCheckedSlots, slotId]);
            } else {
                setCheckedSlots((prevCheckedSlots) => prevCheckedSlots.filter((id) => id !== slotId));
            }
        }
        checkedSlots.reverse();
    };

    return (
        <table className="w-full mb-5 border m-3 shadow-xl">
            <thead className="bg-gray-500 text-gray-200">
            <tr>
                <th className="text-center  border-b-4 border-gray-300 p-4">
                    전체
                    <input
                        type="checkbox"
                        onChange={(event) => handleCheckboxChange(event, 'selectAll')}
                        className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                    />
                </th>
                <th className="text-center  border-b-4 border-gray-300 p-4">
                    ID
                </th>
                <th className="text-center  border-b-4 border-gray-300 p-4">
                    상태
                </th>
                <th className="text-center  border-b-4 border-gray-300 p-4">
                    대행사
                </th>
                <th className="text-center  border-b-4 border-gray-300 p-4">
                    광고주
                </th>
                <th className="text-center  border-b-4 border-gray-300 p-4">
                    플랫폼
                </th>
                <th className="text-center  border-b-4 border-gray-300 p-4">
                    상품명
                </th>
                <th className="text-center  border-b-4 border-gray-300 p-4">
                    브랜드스토어명
                </th>
                <th className="text-center  border-b-4 border-gray-300 p-4">
                    MID
                </th>
                <th className="text-center  border-b-4 border-gray-300 p-4">
                    구매수
                </th>
                <th className="text-center  border-b-4 border-gray-300 p-4">
                    키워드
                </th>
                <th className="text-center  border-b-4 border-gray-300 p-4">
                    상품링크 / 가격 비교 상품 링크
                </th>
                <th className="text-center  border-b-4 border-gray-300 p-4">
                    랭크
                </th>
                <th className="text-center  border-b-4 border-gray-300 p-4">
                    시작/종료 일
                </th>
                <th className="text-center  border-b-4 border-gray-300 p-4">
                    생성/수정 시간
                </th>
                <th className="text-center  border-b-4 border-gray-300 p-4">
                    옵션
                </th>
            </tr>
            </thead>
            <tbody className="bg-gray-200 rounded">
            {userSlots.length !== 0 ? (
                userSlots.map((userSlot, index) => (
                    <tr
                        key={userSlot.id}
                        className="text-sm bg-gray-100 hover:bg-gray-200 active:bg-gray-300 border border-gray-300 py-1 px-2 rounded mr-2"
                        onClick={() => {
                            setSlotModalOpen(true);
                        }}
                        onMouseOver={() => {
                            let data = userSlots[index];
                            data['userId'] = localStorage.getItem('slotUserId');
                            data['userType'] = localStorage.getItem('slotUserType');
                            setSelectedSlot(data);
                        }}
                        onTouchStart={() => {
                            let data = userSlots[index];
                            data['userId'] = localStorage.getItem('slotUserId');
                            data['userType'] = localStorage.getItem('slotUserType');
                            setSelectedSlot(data);
                        }}
                    >
                        <td className="text-center border-b-4 border-gray-300 p-4">
                            <input
                                type="checkbox"
                                checked={checkedSlots.includes(userSlot.id)}
                                onChange={(event) => handleCheckboxChange(event, userSlot.id)}
                                onClick={(event) => event.stopPropagation()}
                                className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                            />
                        </td>
                        <td className="text-center border-b-4 border-gray-300 p-4">
                            {userSlot.id}
                        </td>
                        <td className="text-center border-b-4 border-gray-300 p-4">
                            {showStatus(userSlot.status)}
                        </td>
                        <td className="text-center border-b-4 border-gray-300 p-4">
                            {userSlot.agency.name}
                        </td>
                        <td className="text-center border-b-4 border-gray-300 p-4">
                            {userSlot.advertiser.name}
                        </td>
                        <td className="text-center border-b-4 border-gray-300 p-4">
                            {userSlot.platform.name}
                        </td>
                        <td className="text-center border-b-4 border-gray-300 p-4">
                            {userSlot.product_name}
                        </td>
                        <td className="text-center border-b-4 border-gray-300 p-4">
                            {userSlot.brand_store_name}
                        </td>
                        <td className="text-center border-b-4 border-gray-300 p-4">
                            {userSlot.product_mid}
                        </td>
                        <td className="text-center border-b-4 border-gray-300 p-4">
                            {userSlot.purchased_count}
                        </td>
                        <td className="text-center border-b-4 border-gray-300 p-4 break-all">
                            {userSlot.keyword}
                        </td>
                        <td className="text-center border-b-4 border-gray-300 p-4 break-all">
                            <NormalButton onClick={() => {
                            }} name={userSlot.url}/>
                            {userSlot.compare_url ? <NormalButton onClick={() => {
                            }} name={userSlot.compare_url}/> : <></>}
                        </td>
                        <td className="text-center border-b-4 border-gray-300 p-4">
                            {userSlot.rank}
                        </td>
                        <td className="text-center border-b-4 border-gray-300 p-4">
                            <NormalButton onClick={() => {
                            }} name={userSlot.start_date}/>
                            <NormalButton onClick={() => {
                            }} name={userSlot.end_date}/>
                        </td>
                        <td className="text-center border-b-4 border-gray-300 p-4">
                            <NormalButton onClick={() => {
                            }} name={String(userSlot.created_at).substring(0, 19).replace('T', ' ')}/>
                            <NormalButton onClick={() => {
                            }} name={String(userSlot.updated_at).substring(0, 19).replace('T', ' ')}/>
                        </td>
                        <td className="text-center border-b-4 border-gray-300 p-4">
                            {userType !== 'advertiser' ? (<>
                                <UpdateButton
                                    name="히스토리 로그"
                                    onClick={handleShowHistoryLog}/>
                            </>) : (<></>)}
                            <UpdateButton
                                name="랭크 그래프"
                                onClick={handleShowRankLog}
                            />
                        </td>
                    </tr>
                ))
            ) : (
                <tr className="border p-2">
                    <CancelButton name="슬롯이 없습니다."/>
                </tr>
            )}
            </tbody>
        </table>
    );
}