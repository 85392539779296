// src/components/AddUserModal.js
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useNavigate} from 'react-router-dom';
import '../../style/AddUserModal.css';
import AddButton from './button/AddButton';
import CancelButton from './button/CancelButton';
import NormalButton from './button/NormalButton';
import UpdateButton from './UpdateButton';
import axiosInstance from "../../shared/axiosInstance";

function SlotModal(props) {
    return (
        <div
            className={`modal flex items-center justify-center
    ${props.isOpen ? 'block' : 'invisible'}`}
        >
            <div className="modal-dialog w-1/2 mx-auto" style={{width: '50%'}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h2><CancelButton name={"에러 메시지"}/></h2>
                        <button className="close" onClick={props.onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="flex">
                            {props.message}
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <div class="flex">
                            <NormalButton name="닫기" onClick={props.onClose}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SlotModal;
