// src/components/AddUserModal.js
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useNavigate} from 'react-router-dom';
import '../../../style/AddUserModal.css';
import AddButton from '../../common/button/AddButton';
import CancelButton from '../../common/button/CancelButton';
import NormalButton from '../../common/button/NormalButton';
import UpdateButton from '../../common/UpdateButton';
import axiosInstance from "../../../shared/axiosInstance";

function SlotModal(props) {
    const navigate = useNavigate();
    const [keyword, setKeyword] = useState();
    const [url, setUrl] = useState();
    const [compareUrl, setCompareUrl] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [publicMemo, setPublicMemo] = useState();
    const [privateMemo, setPrivateMemo] = useState();
    const token = localStorage.getItem('token');

    const handlePublicMemo = (e) => {
        const {name, value} = e.target;
        setPublicMemo(value);
    }

    const handlePrivateMemo = (e) => {
        const {name, value} = e.target;
        setPrivateMemo(value);
    }

    const handleKeyword = (e) => {
        const {name, value} = e.target;
        setKeyword(value);
    }

    const handleUrl = (e) => {
        const {name, value} = e.target;
        setUrl(value);
    }

    const handleCompareUrl = (e) => {
        const {name, value} = e.target;
        setCompareUrl(value);
    }

    const handleCreateDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const updateUser = async () => {
        const data = {};

        if (startDate) {
            data.start_date = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`;
        }

        if (endDate) {
            data.end_date = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}`;
        }

        if (keyword) {
            data.keyword = keyword;
        }

        if (url) {
            data.url = url;
        }
        if (compareUrl){
            // data.compare_url = compareUrl;
            data.compare_url = compareUrl || '';
        }

        if (privateMemo) {
            data.private_memo = privateMemo;
        }

        if (publicMemo) {
            data.public_memo = publicMemo;
        }

        props.handleUpdateSlotAll(data);
    };

    const deleteSlot = () => {
        props.handleDeleteSlotAll();
    }

    const inActiveSlot = () => {
        props.handleInActiveSlotAll();
    }

    useEffect(() => {
    }, [props.isOpen, props.data]);

    return (
        <div
            className={`modal flex items-center justify-center
    ${props.isOpen ? 'block' : 'invisible'}`}
        >
            <div className="modal-dialog w-1/2 mx-auto" style={{width: '70%'}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>슬롯 정보</h2>
                        <button className="close" onClick={props.onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="grid grid-cols-1 gap-4">
                            <div className="flex flex-col space-y-2">
                                <div className="flex space-x-2 flex-1">
                                    <div className="p-2 bg-gray-100 w-full">
                                        <label className="m-2">키워드</label>
                                        <input
                                            type="text"
                                            id="keyword"
                                            name="keyword"
                                            value={keyword}
                                            placeholder="키워드를 재입력하세요."
                                            className="border rounded w-full py-2 px-3"
                                            onChange={handleKeyword}
                                        />
                                    </div>
                                </div>
                                <div className="flex space-x-2 flex-1">
                                    <div className="p-2 bg-gray-100 w-full">
                                        <label className="m-2">상품링크</label>
                                        <input
                                            type="text"
                                            id="url"
                                            name="url"
                                            value={url}
                                            placeholder="상품링크를 재입력하세요."
                                            className="border rounded w-full py-2 px-3"
                                            onChange={handleUrl}
                                        />
                                    </div>
                                </div>
                                <div className="flex space-x-2 flex-1">
                                    <div className="p-2 bg-gray-100 w-full">
                                        <label className="m-2">가격 비교 상품 링크</label>
                                        <input
                                            type="text"
                                            id="compare_url"
                                            name="compare_url"
                                            value={compareUrl}
                                            placeholder="가격비교 상품링크를 재입력하세요.."
                                            className="border rounded w-full py-2 px-3"
                                            onChange={handleCompareUrl}
                                        />
                                    </div>
                                </div>
                                <div className="flex space-x-2 flex-1">
                                    <div className="p-2 bg-gray-100 w-1/2">
                                        <label className="m-2">시작일</label>
                                        <DatePicker
                                            id="createdAt"
                                            selected={startDate}
                                            onChange={handleCreateDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            className="border text-center rounded py-2 w-full focus:outline-none focus:border-blue-500"
                                            popperClassName="rounded border shadow-md"
                                        />
                                    </div>
                                    {/*<div className="p-2 bg-gray-100 w-1/2">*/}
                                    {/*    <label className="m-2">종료일</label>*/}
                                    {/*    <DatePicker*/}
                                    {/*        id="endDate"*/}
                                    {/*        selected={endDate}*/}
                                    {/*        onChange={handleEndDateChange}*/}
                                    {/*        dateFormat="yyyy-MM-dd"*/}
                                    {/*        className="border text-center rounded py-2 w-full focus:outline-none focus:border-blue-500"*/}
                                    {/*        popperClassName="rounded border shadow-md"*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                </div>
                            </div>

                            {/* 3 */}
                            <div className="flex">
                                <div className="flex flex-1 space-x-2">
                                    <div className="flex flex-1">
                                        <div className="p-2 bg-gray-100 flex flex-col flex-1">
                                            <label className="m-2">공개 메모</label>
                                            <textarea
                                                id="public_memo"
                                                name="public_memo"
                                                value={publicMemo}
                                                rows="4"
                                                placeholder="공개 메모를 입력하세요"
                                                className="border rounded py-2 px-3 flex-1"
                                                onChange={handlePublicMemo}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 4 */}
                            <div className="flex">
                                <div className="flex flex-1 space-x-2">
                                    <div className="flex flex-1">
                                        <div className="p-2 bg-gray-100 flex flex-col flex-1">
                                            <label className="m-2">비공개 메모</label>
                                            <textarea
                                                id="private_memo"
                                                name="private_memo"
                                                value={privateMemo}
                                                rows="4"
                                                placeholder="비공개 메모를 입력하세요"
                                                className="border rounded py-2 px-3 flex-1"
                                                onChange={handlePrivateMemo}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <div class="flex">
                            <UpdateButton name="정보 수정" onClick={updateUser}/>
                        </div>
                        <div className="flex">
                            <CancelButton name="비활성화" onClick={inActiveSlot}/>
                        </div>
                        <div class="flex">
                            <NormalButton name="취소" onClick={props.onClose}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SlotModal;
