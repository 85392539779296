// src/components/AddUserModal.js
import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useNavigate} from 'react-router-dom';
import axiosInstance from '../../../shared/axiosInstance';
import '../../../style/AddUserModal.css';
import AddButton from '../../common/button/AddButton';
import AdvertiserDropdown from '../../common/drop-down/AdvertiserDropdown';
import AgencyDropdown from '../../common/drop-down/AgencyDropdown';
import CancelButton from '../../common/button/CancelButton';
import NormalButton from '../../common/button/NormalButton';
import PlatformDropdown from '../../common/drop-down/PlatformDropdown';
import ErrorModal from "../../common/ErrorModal";

function AddSlotPeriodModal(props) {
    const navigate = useNavigate();
    const [userTicket, setUserTicket] = useState([]);
    const [selectedRow, setSelectedRow] = useState(-1);
    const [usedDays, setUsedDays] = useState(null);
    const token = localStorage.getItem('token');
    const [selectTicket, setSelectTicket] = useState({
        name: '',
        period: '',
        price: ''
    });
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const openModal = () => {
        setIsErrorModalOpen(true);
    };

    const closeModal = () => {
        setIsErrorModalOpen(false);
    };

    const handleAddSlotPeriod = async () => {
        const config = {
            headers: {
                'Content-Type': 'text/plain',
                Authorization: 'Bearer ' + token,
            },
        };

        let data = {
            'agency_id': parseInt(props.data.userId),
            'used_ticket_id': parseInt(userTicket[selectedRow].ticket.id)
        };

        axiosInstance
            .post(`/api/v1/accounts/${props.data.userId}/slots/${props.slotInfo.id}/agency/date`, data, config)
            .then((response) => {
                window.location.reload();
                props.onClose();
            })
            .catch((error) => {
                if (error.response === 'Unauthorized') {
                    navigate('/');
                    localStorage.removeItem('token');
                    localStorage.removeItem('nickname');
                }
                console.log(error);
                props.onClose();
            })
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setUsedDays(value);
    };

    // useEffect(() => {
    //     const dist = calcTotalPeriod();
    //     const end = new Date(createDate);
    //     end.setDate(createDate.getDate() + dist);
    //     setEndDate(end);
    // }, [selectTicket]);

    return (
        <div
            className={`modal flex items-center justify-center
    ${props.isOpen ? 'block' : 'invisible'}`}
        >
            <div className="modal-dialog w-1/3 mx-auto">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>기간 연장</h2>
                        <button className="close" onClick={props.onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body flex flex-1">
                        <div className="flex flex-col flex-1 space-y-4">
                            <div className='flex'>
                                <div>
                                    <div className="flex space-x-2 flex-1">
                                        <div className="p-2 bg-gray-100 w-full">
                                            <label className="m-2">연장할 기간 수</label>
                                            <input
                                                type="text"
                                                id="url"
                                                name="url"
                                                value={usedDays}
                                                placeholder="연장할 기간 수를 입력하세요."
                                                className="border rounded w-full py-2 px-3"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="flex justify-end">
                        <AddButton name="적용" onClick={() => {
                            const missingFields = [];

                            if (!usedDays) {
                                missingFields.push('연장할 일 수');
                            }

                            if (missingFields.length === 0) {
                                props.handleAddPeriodAll(usedDays);
                                props.onClose();
                            } else {
                                const errorMessage = `입력되지 않은 항목: ${missingFields.join(', ')}`;
                                setErrorMessage(errorMessage);
                                openModal();
                            }
                        }}/>
                        <NormalButton name="취소" onClick={props.onClose}/>
                    </div>
                </div>
            </div>

            <ErrorModal
                isOpen={isErrorModalOpen === true}
                onClose={closeModal}
                message={errorMessage}
            />
        </div>
    );
}

export default AddSlotPeriodModal;
