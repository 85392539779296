import React, {Component} from 'react';
import '../../style/font.css';

class SubTitle extends Component {
    render() {
        const name = this.props.name;

        return (
            <h2 className="hover:bg-gray-600 active:bg-gray-700 py-1 px-2 rounded mr-2">
                {name}
            </h2>
        );
    }
}

export default SubTitle;
