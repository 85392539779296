// src/components/FilterDropdown.js
import axios from 'axios';
import {React, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axiosInstance from "../../../shared/axiosInstance";

const TicketDropdown = ({onFilter}) => {
    const navigate = useNavigate();
    const [datas, setDatas] = useState(null);
    const [selectedOption, setSelectedOption] = useState('all'); // 초기 선택: 모두
    const token = localStorage.getItem('token');

    const getData = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };
            const response = await axiosInstance.get('/api/v1/ticket/all', config);
            setDatas(response.data.ticket_list);
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }
            console.log(error);
        }
    };

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
        onFilter(value);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="inline-block">
            <select
                defaultValue={1}
                value={selectedOption}
                onChange={handleOptionChange}
                className="border rounded py-2 px-3"
                id="selectedTicket"
            >
                {datas != null ? (
                    datas.map((data) => <option value={data.id}>{data.name}</option>)
                ) : (
                    <option>null</option>
                )}
                <option></option>
            </select>
        </div>
    );
};

export default TicketDropdown;
