// src/components/Dashboard.js
import axios from 'axios';
import {React, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import AddButton from '../../common/button/AddButton';
import CancelButton from '../../common/button/CancelButton';
import FilterDropdown from '../../common/drop-down/FilterDropdown';
import Header from '../../common/Header';
import HistoryLogModal from './HistoryLogModal';
import NormalButton from '../../common/button/NormalButton';
import SlotModal from './SlotModal';
import UpdateButton from '../../common/UpdateButton';
import axiosInstance from "../../../shared/axiosInstance";
import OptionButton from "../../common/button/OptionButton";
import SlotFilterDropdown from "../../common/drop-down/SlotFilterDropdown";
import RankLogModal from "./RankLogModal";
import DatePicker from "react-datepicker";
import SlotHistoryFilterDropdown from "../../common/drop-down/SlotHistoryFilterDropdown";
import ErrorModal from "../../common/ErrorModal";
import SlotHistoryUserDropdown from "../../common/drop-down/SlotHistoryUserDropdown";
import PageSizeFilterDropdown from "../../common/drop-down/PageSizeFilterDropdown";
import {renderPageNumbers} from "../../common/util/Util";
import {PageInfo} from "../Page/PageInfo";

function SlotHistoryManagement(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [userSlots, setUserSlots] = useState([]);
    const [tickets, setTickets] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isAddSlotModalOpen, setAddSlotModalOpen] = useState(false);
    const [historyLogModalOpen, setHistoryLogModalOpen] = useState(false);
    const [rankLogModalOpen, setRankLogModalOpen] = useState(false);
    const [slotModalOpen, setSlotModalOpen] = useState(false);
    const [isAddSlotModalExcelOpen, setAddSlotModalExcelOpen] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState({
        advertiser: {},
        agency: {},
        created_at: '',
        end_date: '',
        id: '',
        keyword: '',
        platform: {},
        private_memo: '',
        public_memo: '',
        rank: '',
        slot_log: [],
        url: '',
        compare_url: '',
        userInfo: {},
    });
    const [selectUserInfo, setSelectUserInfo] = useState({
        userId: '',
        userType: '',
    });

    const token = localStorage.getItem('token');
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [selectEventOption, setSelectEventOption] = useState(0);
    const [selectUserOption, setSelectUserOption] = useState(0);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCreateDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const openModal = () => {
        setIsErrorModalOpen(true);
    };

    const closeModal = () => {
        setIsErrorModalOpen(false);
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const getSlot = async () => {
        const config = {
            headers: {
                'Content-Type': 'text/plain',
                Authorization: 'Bearer ' + token,
            },
            params: {
                page: page,
                page_size: parseInt(pageSize),
                // searched: document.getElementById('searched').value,
            },
        };

        if (parseInt(selectEventOption) !== 0) {
            config.params['slot_event_type'] = selectEventOption;
            config.params['page'] = 1;
            setPage(1);
        }

        if (parseInt(selectUserOption) !== 0) {
            config.params['user_id'] = selectUserOption;
            config.params['page'] = 1;
            setPage(1);
        }

        if (startDate != null) {
            config.params['start_date'] = formatDate(startDate);
        }

        if (endDate != null) {
            config.params['end_date'] = formatDate(endDate);
        }


        const userId = localStorage.getItem('slotUserId');
        axiosInstance
            .get(`/api/v1/accounts/${userId}/slots/log/${props.userType}/all`, config)
            .then((response) => response.data)
            .then((data) => {
                setUserSlots(data.items);
                setTotalPage(Math.ceil(data.total_count / data.page_size));
            })
            .catch((error) => {
                console.log(error);
                if (error.response.statusText === 'Unauthorized') {
                    navigate('/');
                    localStorage.removeItem('token');
                    localStorage.removeItem('nickname');
                }
                setErrorMessage(error.response.data.detail);
                openModal();
            });
    };

    const slotHistoryExcelDownload = () => {
        const config = {
            headers: {
                'Content-Type': 'text/plain',
                Authorization: 'Bearer ' + token,
            },
            responseType: 'blob',
            params: {
                page: page,
                page_size: pageSize,
                // searched: document.getElementById('searched').value,
            },
        };

        if (parseInt(selectEventOption) !== 0) {
            config.params['slot_event_type'] = selectEventOption;
        }

        if (parseInt(selectUserOption) !== 0) {
            config.params['user_id'] = selectUserOption;
        }

        if (startDate != null) {
            config.params['start_date'] = formatDate(startDate);
        }

        if (endDate != null) {
            config.params['end_date'] = formatDate(endDate);
        }

        axiosInstance
            .get(`/api/v1/slots/excel/log/download/admin`, config)
            .then((response) => {
                const blob = new Blob([response.data], {
                    type: "application/vnd.ms-excel"
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'agency-slot-history.xls'; // 다운로드될 파일의 이름을 설정하세요
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.log(error);
                if (error.response.statusText === 'Unauthorized') {
                    navigate('/');
                    localStorage.removeItem('token');
                    localStorage.removeItem('nickname');
                }
                setErrorMessage(error.response.data);
                openModal();
            });
    }

    const handleEventFilter = (select) => {
        setSelectEventOption(select);
    };

    const handleUserFilter = (select) => {
        setSelectUserOption(select);
    }

    const renderEventType = (type) => {
        switch (type) {
            case "관리자 슬롯 생성":
            case "대행사 슬롯 생성":
                return <AddButton name={type}/>
            case "관리자 슬롯 수정":
            case "대행사 슬롯 수정":
            case "광고주 슬롯 수정":
                return <UpdateButton name={type}/>
            case "관리자 슬롯 기간 연장":
            case "대행사 슬롯 기간 연장":
                return <OptionButton name={type}/>
            case "관리자 슬롯 비활성화":
            case "대행사 슬롯 비활성화":
                return <CancelButton name={type}/>
        }
    }

    useEffect(() => {
        getSlot();
    }, [page, pageSize, startDate, endDate, selectEventOption, selectUserOption]);

    return (
        <div className="p-5 min-h-screen">
            <Header name="슬롯 로그 목록"></Header>
            <div className="flex mb-5 m-3 justify-between">
                <div className="flex flex-1 space-x-10">
                    <label className="flex space-x-2 text-gray-600">
                        <div className='flex items-center'>페이지당</div>
                        <PageSizeFilterDropdown
                            setPage={setPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}/>
                    </label>
                    <label className="flex space-x-2 text-gray-600">
                        <div className='flex items-center'>이벤트 필터</div>
                        <SlotHistoryFilterDropdown onFilter={handleEventFilter}/>
                    </label>
                    {props.userType === 'admin' ?
                        <label className="flex space-x-2 text-gray-600">
                            <div className='flex items-center'>유저 필터</div>
                            <SlotHistoryUserDropdown onFilter={handleUserFilter}/>
                        </label> : <></>
                    }

                    <label className="flex space-x-2 text-gray-600">
                        <div className='flex items-center'>시작일</div>
                        <DatePicker
                            id="startDate"
                            selected={startDate}
                            onChange={handleCreateDateChange}
                            dateFormat="yyyy-MM-dd"
                            className="border text-center rounded py-2 w-full focus:outline-none focus:border-blue-500"
                            popperClassName="rounded border shadow-md"
                        />
                    </label>
                    <label className="flex space-x-2 text-gray-600">
                        <div className='flex items-center'>종료일</div>
                        <DatePicker
                            id="endDate"
                            selected={endDate}
                            onChange={handleEndDateChange}
                            dateFormat="yyyy-MM-dd"
                            className="border text-center rounded py-2 w-full focus:outline-none focus:border-blue-500"
                            popperClassName="rounded border shadow-md"
                        />
                    </label>
                    <label className="flex space-x-2 text-gray-600 flex-1 text-center">
                        <div className='flex items-center'>검색</div>
                        <input
                            type="text"
                            id="searched"
                            name="searched"
                            placeholder="검색어를 입력하세요"
                            className="border rounded w-[50%] py-2 px-3"
                            //onKeyDown={handleSearch}
                        />
                        <UpdateButton name="조회" onClick={getSlot}/>
                    </label>
                    <label className="text-gray-600">
                        <UpdateButton
                            name="진행중 슬롯 수정 로그 다운로드"
                            onClick={() => {
                                slotHistoryExcelDownload();
                            }}
                        />
                    </label>
                </div>
            </div>
            <table className="w-full mb-5 border m-3 shadow-xl">
                <thead className="bg-gray-500 text-gray-200">
                <tr>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        ID
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        이벤트 타입
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        슬롯 ID
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        플랫폼
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        대행사
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        광고주
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        수정자
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        일 수
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        로그 생성일
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        현재 시작일
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        현재 종료일
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        키워드(변경 전) / (변경 후)
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        url(변경 전) / (변경 후)
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        가격 비교 url(변경 전) / (변경 후)
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        시작일(변경 전) / (변경 후)
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        종료일(변경 전) / (변경 후)
                    </th>
                </tr>
                </thead>
                <tbody className="bg-gray-200 rounded">
                {userSlots.length !== 0 ? (
                    userSlots.map((userSlot, index) => (
                        <tr
                            class="text-sm bg-gray-100 hover:bg-gray-200 active:bg-gray-300 border border-gray-300 py-1 px-2 rounded mr-2"
                            onClick={() => {
                                setSlotModalOpen(true);
                            }}
                            onMouseOver={() => {
                                let data = userSlots[index];
                                data['userId'] = localStorage.getItem('slotUserId');
                                data['userType'] = localStorage.getItem('slotUserType');
                                setSelectedSlot(data);
                            }}
                            onTouchStart={() => {
                                let data = userSlots[index];
                                data['userId'] = localStorage.getItem('slotUserId');
                                data['userType'] = localStorage.getItem('slotUserType');
                                setSelectedSlot(data);
                            }}
                        >
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {userSlot.id}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {renderEventType(userSlot.event_type)}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {userSlot.slot.id}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {userSlot.slot.platform.name}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {userSlot.slot.agency.nickname}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {userSlot.slot.advertiser.nickname}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {userSlot.writer.nickname}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {userSlot.used_days}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {String(userSlot.created_at).substring(0, 19).replace('T', ' ')}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {userSlot.slot.start_date}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {userSlot.slot.end_date}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                <NormalButton onClick={() => {
                                }} name={userSlot.before_keyword}/>
                                <NormalButton onClick={() => {
                                }} name={userSlot.after_keyword}/>
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                <NormalButton onClick={() => {
                                }} name={userSlot.before_url}/>
                                <NormalButton onClick={() => {
                                }} name={userSlot.after_url}/>
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4 break-all">
                                <NormalButton onClick={() => {
                                }} name={userSlot.before_compare_url}/>
                                <NormalButton onClick={() => {
                                }} name={userSlot.after_compare_url}/>
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                <NormalButton onClick={() => {
                                }} name={userSlot.before_start_date}/>
                                <NormalButton onClick={() => {
                                }} name={userSlot.after_start_date}/>
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                <NormalButton onClick={() => {
                                }} name={userSlot.before_end_date}/>
                                <NormalButton onClick={() => {
                                }} name={userSlot.after_end_date}/>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr class="border p-2">
                        <CancelButton name="슬롯이 없습니다."/>
                    </tr>
                )}
                </tbody>
            </table>
            <PageInfo page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize}
                      totalPage={totalPage}/>

            <ErrorModal
                isOpen={isErrorModalOpen === true}
                onClose={closeModal}
                message={errorMessage}
            />
        </div>
    );
}

export default SlotHistoryManagement;
