import React, {Component} from 'react';

class AddButton extends Component {
    render() {
        const name = this.props.name;

        return (
            <button
                className="flex bg-green-500 hover:bg-green-600 active:bg-green-700 text-white py-1 px-2 rounded m-2"
                onClick={() => {
                    this.props.onClick && this.props.onClick();
                }}
            >
                {name}
            </button>
        );
    }
}

export default AddButton;
