// src/components/FilterDropdown.js
import React, {useState} from 'react';

const FilterDropdown = ({selectedOption, setSelectedOption, userType}) => {
    const renderOptions = () => {
        switch (userType) {
            case 'admin':
                return (
                    <>
                        <option value="4">모두</option>
                        <option value="1">대행사</option>
                        <option value="2">광고주</option>
                    </>
                );
            case 'agency':
                return (
                    <>
                        <option value="4">모두</option>
                        <option value="2">광고주</option>
                    </>
                );
        }
    };

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
    };

    return (
        <div className="inline-block flex items-center">
            <select
                value={selectedOption}
                onChange={handleOptionChange}
                className="border rounded py-2 px-3"
            >
                {renderOptions()}
            </select>
        </div>
    );
};

export default FilterDropdown;
