import axios from 'axios';
import {React, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import AddButton from '../../common/button/AddButton';
import AddSlotModal from './AddSlotModal';
import CancelButton from '../../common/button/CancelButton';
import FilterDropdown from '../../common/drop-down/FilterDropdown';
import Header from '../../common/Header';
import HistoryLogModal from './HistoryLogModal';
import NormalButton from '../../common/button/NormalButton';
import SlotModal from './SlotModal';
import UpdateButton from '../../common/UpdateButton';
import axiosInstance from "../../../shared/axiosInstance";
import OptionButton from "../../common/button/OptionButton";
import SlotFilterDropdown from "../../common/drop-down/SlotFilterDropdown";
import AddSlotModalExcel from "./AddSlotModalExcel";
import RankLogModal from "./RankLogModal";
import AddSlotPeriodModal from "./AddSlotPeriodModal";
import AllSlotModal from "./AllSlotModal";
import AgencyAddSlotPeriodModal from "../../agency/slot/AddSlotPeriodModal";
import AgencyAddSlotModalExcel from "../../agency/slot/AddSlotModalExcel";
import AgencyAddSlotModal from "../../agency/slot/AddSlotModal";
import ErrorModal from "../../common/ErrorModal";
import PageSizeFilterDropdown from "../../common/drop-down/PageSizeFilterDropdown";
import SlotSortFilterDropdown from "../../common/drop-down/SlotSortFilterDropdown";
import {getSlotConfig, renderPageNumbers} from "../../common/util/Util";
import {initialSelectedSlot, initialSelectUserInfo} from "../util/initialValue";
import {DataTable} from "../Table/DataTable";
import {PageInfo} from "../Page/PageInfo";
import {ManagementOptionBar} from "../OptionBar/ManagementOptionBar";
import {ManagementSearchBar} from "../OptionBar/ManagementSearchBar";

function SlotManagement(props) {
    const {userType} = props;
    const navigate = useNavigate();
    const [userSlots, setUserSlots] = useState([]);
    const [extraFieldNames, setExtraFieldNames] = useState();
    const [tickets, setTickets] = useState();
    const [slotData, setSlotData] = useState();
    const [selectedSlot, setSelectedSlot] = useState(initialSelectedSlot);
    const [selectUserInfo, setSelectUserInfo] = useState(initialSelectUserInfo);
    const token = localStorage.getItem('token');

    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [statusOption, setStatusOption] = useState(0);
    const [sortOption, setSortOption] = useState(0);
    const [checkedSlots, setCheckedSlots] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const [isAddSlotModalOpen, setAddSlotModalOpen] = useState(false);
    const [historyLogModalOpen, setHistoryLogModalOpen] = useState(false);
    const [rankLogModalOpen, setRankLogModalOpen] = useState(false);
    const [slotModalOpen, setSlotModalOpen] = useState(false);
    const [isAddSlotModalExcelOpen, setAddSlotModalExcelOpen] = useState(false);
    const [isAddSlotPeriodModalOpen, setIsAddSlotPeriodModalOpen] = useState(false);
    const [isAllSlotModalOpen, setIsAllSlotModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    async function getSlots(event, nPage = 1, nPageSize = 10) {
        const config = {
            headers: {
                'Content-Type': 'text/plain',
                Authorization: 'Bearer ' + token,
            },
            params: {
                page: nPage,
                page_size: parseInt(nPageSize),
                searched: document.getElementById('searched').value,
            },
        };

        if (parseInt(statusOption) !== 0) {
            config.params['slot_type'] = parseInt(statusOption);
            config.params['page'] = 1;
            setPage(1);
        }
        if (parseInt(sortOption) !== 0) {
            config.params['order_by'] = parseInt(sortOption);
            config.params['page'] = 1;
            setPage(1);
        }

        let userId = '';
        let slotUserType = '';
        //const userType = localStorage.getItem('slotUserType');

        if (userType !== 'adv') {
            userId = localStorage.getItem('slotUserId');
            slotUserType = localStorage.getItem('slotUserType');

            setSelectUserInfo({
                userId: parseInt(userId),
                userType: parseInt(slotUserType)
            })
        } else {
            userId = localStorage.getItem("user_id");
        }

        axiosInstance
            .get(`/api/v1/accounts/${userId}/slots/all`, config)
            .then((response) => response.data)
            .then((data) => {
                setUserSlots(data.items);
                setPage(nPage);
                setPageSize(nPageSize);
                setTotalPage(Math.ceil(data.total_count / data.page_size));
            })
            .catch((error) => {
                console.log(error);
                if (error.response.statusText === 'Unauthorized') {
                    navigate('/');
                    localStorage.removeItem('token');
                    localStorage.removeItem('nickname');
                }
                setErrorMessage(error.response.data.detail);
                setIsErrorModalOpen(true);
            });

        setExtraFieldNames(await getSlotConfig(token, localStorage.getItem('user_id')));
    };

    const handleSearch = (event) => {
        if (event.keyCode === 13) {
            getSlots(event, page, pageSize);
        }
    };

    const handleAddUser = (newUser) => {
        const newId = Math.max(...tickets.map((ticket) => ticket.id)) + 1;
        setTickets([...tickets, {id: newId, ...newUser, status: 'active'}]);
        setAddSlotModalOpen(false);
    };

    const handleShowHistoryLog = (event) => {
        event.stopPropagation();
        setHistoryLogModalOpen(true);
    };

    const handleShowRankLog = (event) => {
        event.stopPropagation();
        setRankLogModalOpen(true);
    };

    const handlePeriodSlotAll = async (data) => {
        try {
            const promises = checkedSlots.map((slotIdx) => {
                const config = {
                    headers: {
                        'Content-Type': 'text/plain',
                        'Authorization': 'Bearer ' + token,
                    },
                };

                const userId = localStorage.getItem('slotUserId');

                return axiosInstance
                    .post(`/api/v1/accounts/${userId}/slots/${slotIdx}/${userType}/date`, data, config)
                    //.then((response) => {console.log(response);})
                    .catch((error) => {
                        if (error.response === 'Unauthorized') {
                            navigate('/');
                            localStorage.removeItem('token');
                            localStorage.removeItem('nickname');
                        }
                        console.log(error);
                        setErrorMessage(error.response.data);
                        throw error;
                    })
            });

            await Promise.all(promises);
            window.location.reload()
        } catch (error) {
            setIsErrorModalOpen(true);
        }
    }

    const handleAddPeriodAll = async (usedDays) => {
        try {
            const promises = checkedSlots.map(slotIdx => {
                const config = {
                    headers: {
                        'Content-Type': 'text/plain',
                        'Authorization': 'Bearer ' + token,
                    },
                };
                const userId = localStorage.getItem('slotUserId');
                const data = {
                    used_days: parseInt(usedDays),
                };

                return axiosInstance.post(`/api/v1/accounts/${userId}/slots/${slotIdx}/${userType}/date`, data, config)
                    .catch(error => {
                        console.log(error);
                        if (error.response && error.response.statusText === 'Unauthorized') {
                            navigate('/');
                            localStorage.removeItem('token');
                            localStorage.removeItem('nickname');
                        }
                    });
            });

            // Promise.all을 사용하여 모든 요청이 완료될 때까지 기다립니다.
            await Promise.all(promises);
            // 모든 요청이 완료된 후 페이지를 새로고침합니다.
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };
    // 2024 04 07 KDW 수정 - 기존에 여러 개 연장 처리 한꺼번에 할 때 처리가 안됨
    // 원래 코드에서 문제가 발생하는 주요 원인은 map 함수 내에서 생성된 비동기 요청(axiosInstance.post)들이 병렬로 실행되지만,
    // 각각의 요청 완료를 기다리지 않고 즉시 window.location.reload();를 호출하기 때문입니다.
    // 이로 인해 모든 요청이 완료되기 전에 페이지가 새로고침되어 일부 요청이 제대로 처리되지 않는 문제가 발생합니다.

    const handleUpdateSlotAll = async (data) => {
        try {
            const promises = checkedSlots.map((slotIdx, index) => {
                const config = {
                    headers: {
                        'Content-Type': 'text/plain',
                        'Authorization': 'Bearer ' + token,
                    },
                };

                const slot = userSlots.filter(elem => elem.id === slotIdx);
                const newData = {...slot[0], ...data};
                const userId = localStorage.getItem('slotUserId');

                return axiosInstance
                    .patch(`/api/v1/accounts/${userId}/slots/${slotIdx}/${userType}/info`, newData, config)
                    .then((response) => console.log(response))
                    .catch((error) => {
                        throw error;
                    })
            });
            await Promise.all(promises);
            window.location.reload()
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }
            console.log(error);
        }
    }

    const handleDeleteSlotAll = async () => {
        const promises = checkedSlots.map((slotIdx) => {
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': 'Bearer ' + token,
                },
            };

            const userId = localStorage.getItem('slotUserId');

            return axiosInstance
                .delete(`/api/v1/accounts/${userId}/slots/${slotIdx}/${userType}`, config)
                //.then((response) => console.log(response))
                .catch((error) => {
                    if (error.response.statusText === 'Unauthorized') {
                        navigate('/');
                        localStorage.removeItem('token');
                        localStorage.removeItem('nickname');
                    }
                    console.log(error);
                })
        });

        await Promise.all(promises);
        window.location.reload()
    }

    const handleInActiveSlotAll = async () => {
        const promises = checkedSlots.map((slotIdx) => {
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': 'Bearer ' + token,
                },
            };

            const userId = localStorage.getItem('slotUserId');

            return axiosInstance
                .delete(`/api/v1/accounts/${userId}/slots/${slotIdx}/inactive`, config)
                //.then((response) => console.log(response))
                .catch((error) => {
                    if (error.response.statusText === 'Unauthorized') {
                        navigate('/');
                        localStorage.removeItem('token');
                        localStorage.removeItem('nickname');
                    }
                    console.log(error);
                    setErrorMessage(error.response.data);
                    setIsErrorModalOpen(true);
                })
        });

        await Promise.all(promises);
        window.location.reload()
    }

    const generateManagementModal = (userType) => {
        const components = [
            <SlotModal
                userType={userType}
                extraFieldNames={extraFieldNames}
                setLabel={setExtraFieldNames}
                isOpen={slotModalOpen === true}
                onClose={() => {
                    setSlotModalOpen(false);
                }}
                data={selectedSlot}
                setData={setSelectedSlot}
                setErrorMessage={setErrorMessage}
                setIsErrorModalOpen={setIsErrorModalOpen}
            />,
            <AllSlotModal
                userType={userType}
                isOpen={isAllSlotModalOpen === true}
                onClose={() => {
                    setIsAllSlotModalOpen(false);
                }}
                extraFieldNames={extraFieldNames}
                data={slotData}
                setData={setSelectedSlot}
                handleUpdateSlotAll={handleUpdateSlotAll}
                handleDeleteSlotAll={handleDeleteSlotAll}
                handleInActiveSlotAll={handleInActiveSlotAll}
            />,
            <RankLogModal
                isOpen={rankLogModalOpen === true}
                onClose={() => {
                    setRankLogModalOpen(false);
                }}
                data={selectedSlot}
                setData={setSelectedSlot}
            />,
            <ErrorModal
                isOpen={isErrorModalOpen === true}
                onClose={() => setIsErrorModalOpen(false)}
                message={errorMessage}
            />
        ];

        switch (userType) {
            case 'admin':
                components.push(<>
                    <AddSlotPeriodModal
                        users={userSlots}
                        setUsers={setUserSlots}
                        isOpen={isAddSlotPeriodModalOpen === true}
                        onClose={() => {
                            setIsAddSlotPeriodModalOpen(false);
                        }}
                        slotInfo={selectedSlot}
                        data={selectUserInfo}
                        onAddUser={handleAddUser}
                        handleAddPeriodAll={handleAddPeriodAll}
                    />
                    <AddSlotModalExcel
                        users={userSlots}
                        setUsers={setUserSlots}
                        isOpen={isAddSlotModalExcelOpen === true}
                        onClose={() => {
                            setAddSlotModalExcelOpen(false);
                        }}
                        data={selectUserInfo}
                        onAddUser={handleAddUser}
                    />
                    <AddSlotModal
                        extraFieldNames={extraFieldNames}
                        users={userSlots}
                        setUsers={setUserSlots}
                        isOpen={isAddSlotModalOpen === true}
                        onClose={() => {
                            setAddSlotModalOpen(false);
                        }}
                        data={selectUserInfo}
                        onAddUser={handleAddUser}
                        userType={props.userType}
                    />
                    <HistoryLogModal
                        isOpen={historyLogModalOpen === true}
                        onClose={() => {
                            setHistoryLogModalOpen(false);
                        }}
                        data={selectedSlot}
                        setData={setSelectedSlot}
                    />
                </>);
                break;
            case 'agency':
                components.push(<>
                    <AgencyAddSlotPeriodModal
                        users={userSlots}
                        setUsers={setUserSlots}
                        isOpen={isAddSlotPeriodModalOpen === true}
                        onClose={() => {
                            setIsAddSlotPeriodModalOpen(false);
                        }}
                        slotInfo={selectedSlot}
                        data={selectUserInfo}
                        onAddUser={handleAddUser}
                        handlePeriodSlotAll={handlePeriodSlotAll}
                    />
                    <AgencyAddSlotModalExcel
                        users={userSlots}
                        setUsers={setUserSlots}
                        isOpen={isAddSlotModalExcelOpen === true}
                        onClose={() => {
                            setAddSlotModalExcelOpen(false);
                        }}
                        data={selectUserInfo}
                        onAddUser={handleAddUser}
                    />
                    <AgencyAddSlotModal
                        extraFieldNames={extraFieldNames}
                        users={userSlots}
                        setUsers={setUserSlots}
                        isOpen={isAddSlotModalOpen === true}
                        onClose={() => {
                            setAddSlotModalOpen(false);
                        }}
                        data={selectUserInfo}
                        onAddUser={handleAddUser}
                    />
                    <HistoryLogModal
                        isOpen={historyLogModalOpen === true}
                        onClose={() => {
                            setHistoryLogModalOpen(false);
                        }}
                        data={selectedSlot}
                        setData={setSelectedSlot}
                    />
                </>)
                break;
            case 'advertiser':
                break;
        }
        
        return <>{components}</>
    }

    useEffect(() => {
        getSlots(null, page, pageSize);
    }, [page, pageSize, statusOption, sortOption]);

    return (
        <div className="p-5 min-h-screen">
            <Header name="슬롯 목록 리스트"></Header>
            <div className="flex mb-5 m-3 justify-between">
                <div className="flex flex-1 space-x-10">
                    <div className="flex flex-col flex-1 space-y-5">
                        <div className="flex space-x-4 flex-1">
                            <ManagementSearchBar
                                setPage={setPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                statusOption={statusOption}
                                setStatusOption={setStatusOption}
                                sortOption={sortOption}
                                setSortOption={setSortOption}
                                handleSearch={handleSearch}
                                getData={getSlots}/>
                            <ManagementOptionBar
                                userType={userType}
                                userSlots={userSlots}
                                checkedSlots={checkedSlots}
                                setSlotData={setSlotData}
                                setIsAllSlotModalOpen={setIsAllSlotModalOpen}
                                setSelectUserInfo={setSelectUserInfo}
                                setIsAddSlotPeriodModalOpen={setIsAddSlotPeriodModalOpen}
                                setAddSlotModalExcelOpen={setAddSlotModalExcelOpen}
                                setAddSlotModalOpen={setAddSlotModalOpen}/>
                        </div>
                    </div>
                </div>
            </div>
            <DataTable userType={userType} userSlots={userSlots} handleShowHistoryLog={handleShowHistoryLog}
                       handleShowRankLog={handleShowRankLog} setSelectedSlot={setSelectedSlot}
                       setSlotModalOpen={setSlotModalOpen} checkedSlots={checkedSlots}
                       setCheckedSlots={setCheckedSlots}/>
            <PageInfo page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize}
                      totalPage={totalPage}/>
            <div>
                {generateManagementModal(userType)}
            </div>
        </div>
    );
}

export default SlotManagement;
