import {renderPageNumbers} from "../util/Util";
import {React} from "react";

export function PageInfo(props) {
    const {page, setPage, pageSize, setPageSize, totalPage} = props;

    return (
        <div className='flex justify-center'>
            {renderPageNumbers(page, setPage, pageSize, setPageSize, totalPage)}
        </div>
    );
}