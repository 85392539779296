// src/components/AddUserModal.js
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import '../../../style/AddUserModal.css';
import NormalButton from '../../common/button/NormalButton';
import {Line} from "react-chartjs-2";
import axiosInstance from "../../../shared/axiosInstance";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import UpdateButton from "../../common/UpdateButton";
import CancelButton from "../../common/button/CancelButton";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function RankLogModal(props) {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [rankHistoryGraph, setRankHistoryGraph] = useState({
        labels: [],
        datasets: [
            {
                label: 'Rank 히스토리',
                data: [],
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                fill: false,
            },
        ],
    });
    const [rankTable, setRankTable] = useState([]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        props.setData({...props.data, [name]: value});
    };



    const getRankHistory = () => {
        const config = {
            headers: {
                'Content-Type': 'text/plain',
                Authorization: 'Bearer ' + token,
            },
        };

        const slotId = props.data.id;

        axiosInstance
            .get(`/api/v1/slots/rank/${slotId}/log`, config)
            .then((response) => response.data.slot_log_list)
            .then((items) => {
                const data = {
                    labels: items
                        .filter((history) => history.rank !== 0)
                        .map((history) => history.rank_created_date),
                    datasets: [
                        {
                            label: 'Rank 하스토리',
                            data: items
                                .filter((history) => history.rank !== 0)
                                .map((history) => history.rank),
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 1,
                            fill: false,
                        },
                    ],
                }
                const table = items.map((history) => ({
                    date: history.rank_created_date,
                    rank: history.rank
                }));

                setRankHistoryGraph(data);
                setRankTable(table);
            })
            .catch((error) => {
                console.log(error);
                if (error.response.statusText === 'Unauthorized') {
                    navigate('/');
                    localStorage.removeItem('token');
                    localStorage.removeItem('nickname');
                }
            });
    }

    useEffect(() => {
    // 2024 0410 KDW 모달이 열리고 props.data가 정의되어 있으며, props.data.id가 있는 경우에만 getRankHistory 함수 실행
    if (props.isOpen && props.data && props.data.id) {
        getRankHistory();
    }
}, [props.isOpen, props.data]); // 의존성 배열에 props.isOpen과 props.data를 추가


    return (
        <div
            className={`modal flex items-center justify-center
    ${props.isOpen ? 'block' : 'invisible'}`}
        >
            <div className="modal-dialog flex flex-col justify-between space-y-4 w-1/2 h-3/4">
                <div className="modal-content flex flex-col flex-1">
                    <div className="modal-header">
                        <h2>슬롯 랭크 정보</h2>
                        <button className="close" onClick={props.onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body flex flex-1 flex-col">
                        <div className="flex h-full flex-1">
                            <div className="flex flex-1">
                                <table className="w-full mb-5 border m-3 shadow-xl">
                                    <thead className="bg-gray-500 text-gray-200">
                                    <tr>
                                        <th className="text-center  border-b-4 border-gray-300 p-2">
                                            시간
                                        </th>
                                        <th className="text-center  border-b-4 border-gray-300 p-2">
                                            랭크
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="bg-gray-200 rounded">
                                    {rankTable.length !== 0 ? (
                                        rankTable.map((history, index) => (
                                            <tr
                                                className="text-sm bg-gray-100 hover:bg-gray-200 active:bg-gray-300 border border-gray-300 py-1 px-2 rounded mr-2"
                                            >
                                                <td className="text-center border-b-4 border-gray-300 p-2">
                                                    {history.date}
                                                </td>
                                                <td className="text-center border-b-4 border-gray-300 p-2">
                                                    {history.rank}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr className="border p-2">
                                            <CancelButton name="슬롯이 없습니다."/>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex flex-1 w-3/4">
                                <div className="flex flex-col flex-1 p-2 h-5/6">
                                    <div className="flex flex-1">
                                        <Line
                                            data={rankHistoryGraph}
                                            options={{
                                                scales: {
                                                    x: [
                                                        {
                                                            type: 'time', // 수정된 부분
                                                            time: {
                                                                unit: 'hour', // 시간 단위를 조절할 수 있습니다.
                                                                displayFormats: {
                                                                    hour: 'YYYY-MM-DD HH:mm', // 시간 표시 형식을 지정할 수 있습니다.
                                                                },
                                                            },
                                                        },
                                                    ],
                                                    y: {
                                                        beginAtZero: true,
                                                        stepSize: 1,
                                                        reverse: true
                                                    },
                                                },
                                            }}
                                            width="100%"
                                            height="70%"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end py-4 px-2">
                            <div className="flex">
                                <NormalButton name="취소" onClick={props.onClose}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RankLogModal;
