import React, { Component } from 'react';

class Header extends Component {
  render() {
    const name = this.props.name;

    return <div className="text-2xl font-semibold mb-4">{name}</div>;
  }
}

export default Header;
