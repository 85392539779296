// src/components/AddUserModal.js
import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useNavigate} from 'react-router-dom';
import axiosInstance from '../../../shared/axiosInstance';
import '../../../style/AddUserModal.css';
import AddButton from '../../common/button/AddButton';
import AdvertiserDropdown from '../../common/drop-down/AdvertiserDropdown';
import AgencyDropdown from '../../common/drop-down/AgencyDropdown';
import CancelButton from '../../common/button/CancelButton';
import NormalButton from '../../common/button/NormalButton';
import PlatformDropdown from '../../common/drop-down/PlatformDropdown';

function AddSlotModal(props) {
    const navigate = useNavigate();
    const [userTicket, setUserTicket] = useState([]);
    const [selectedRow, setSelectedRow] = useState(-1);
    const [createDate, setCreateDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [formData, setFormData] = useState({
        slot_cnt: '',
        keyword: '',
        url: '',
        compare_url:'',
        private_memo: '',
        public_memo: '',
    });
    const token = localStorage.getItem('token');
    // const [selectTicket, setSelectTicket] = useState([]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleAddSlot = () => {
        // if (formData.slot_cnt &&
        //     formData.keyword &&
        //     formData.url &&
        //     formData.private_memo &&
        //     formData.public_memo &&
        //     document.getElementById('selectedPlatformType').value &&
        //     document.getElementById('selectedAdvertiser').value &&
        //     document.getElementById('selectedAgency').value
        // ) {
        //     addSlot();
        //     props.onClose();
        // }
        addSlot();
        props.onClose();
    };

    const addSlot = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };

            let data = formData;
            data['start_date'] = `${createDate.getFullYear()}-${String(createDate.getMonth() + 1).padStart(2, '0')}-${String(createDate.getDate()).padStart(2, '0')}`;
            data['end_date'] = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}`;
            data['platform_id'] = parseInt(document.getElementById('selectedPlatformType').value);
            data['slot_cnt'] = parseInt(data['slot_cnt']);

            switch (parseInt(props.data.userType)) {
                case 1:
                    data['agency_id'] = parseInt(props.data.userId);
                    data['advertiser_id'] = parseInt(document.getElementById('selectedAdvertiser').value);
                    break;
                case 2:
                    data['agency_id'] = parseInt(document.getElementById('selectedAgency').value);
                    data['advertiser_id'] = parseInt(props.data.userId);
                    break;
            }

            axiosInstance
                .post(`/api/v1/accounts/${props.data.userId}/slots/admin/create`, data, config)
                .then((response) => {
                    window.location.reload();
                    props.onClose();
                })
        } catch (error) {
            if (error.response === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }

            console.log(error);
            props.onClose();
        }
    };

    const showTargetDropdown = () => {
        switch (parseInt(props.data.userType)) {
            case 1:
                return (
                    <>
                        <label className="m-2">광고주</label>
                        <AdvertiserDropdown/>
                    </>
                );
            case 2:
                return (
                    <>
                        <label className="m-2">대행사</label>
                        <AgencyDropdown/>
                    </>
                );
        }
    };

    const getUserTicket = async (userId, platformId) => {
        try {
            if (userId === '' || platformId === '') {
                return;
            }

            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };

            const response = await axiosInstance.get(
                `/api/v1/accounts/${userId}/platform/${platformId}/ticket/detail`,
                config
            );
            setUserTicket(response.data.user_ticket_list);
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }
            console.log(error);
        }
    };

    const handleRowClick = (index) => {
        if (selectedRow === index) {
            setSelectedRow(-1);
        } else {
            setSelectedRow(index);
        }
    };

    const handleCreateDateChange = (date) => {
        setCreateDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    // const handleUseTicket = (index) => {
    //   const selectedTicket = userTicket[index].ticket;

    //   const existTicketIndex = selectTicket.findIndex(
    //     (ticket) => ticket.id === selectedTicket.id
    //   );

    //   if (existTicketIndex !== -1) {
    //     const updatedTickets = [...selectTicket];
    //     updatedTickets[existTicketIndex].count += 1;
    //     userTicket[index].count -= 1;
    //     if (userTicket[index].count === 0) {
    //       userTicket.splice(index, 1);
    //     }
    //     setSelectTicket(updatedTickets);
    //   } else {
    //     const newTicket = {
    //       id: selectedTicket.id,
    //       name: selectedTicket.name,
    //       period: selectedTicket.period,
    //       count: 1,
    //       price: selectedTicket.price,
    //     };

    //     const updatedTickets = [...selectTicket, newTicket];
    //     userTicket[index].count -= 1;
    //     if (userTicket[index].count === 0) {
    //       userTicket.splice(index, 1);
    //     }
    //     setSelectTicket(updatedTickets);
    //   }
    // };

    // const handleCancelTicket = (index) => {
    //   const selectedTicket = selectTicket[index];
    //   const existTicketIndex = userTicket.findIndex(
    //     (ticket) => ticket.ticket.id === selectedTicket.id
    //   );

    //   if (existTicketIndex !== -1) {
    //     userTicket[existTicketIndex].count += 1;
    //   } else {
    //     userTicket.push({
    //       count: 1,
    //       ticket: {
    //         id: selectedTicket.id,
    //         name: selectedTicket.name,
    //         period: selectedTicket.period,
    //         price: selectedTicket.price,
    //       },
    //     });
    //   }

    //   let updatedTickets = [...selectTicket];
    //   updatedTickets[index].count -= 1;
    //   if (updatedTickets[index].count === 0) {
    //     updatedTickets.splice(index, 1);
    //   }
    //   setSelectTicket(updatedTickets);
    // };

    // const calcTotalPeriod = () => {
    //   let total = 0;
    //   selectTicket.forEach((ticket) => {
    //     total += parseInt(ticket.period) * parseInt(ticket.count);
    //   });

    //   return total;
    // };

    // const handleCreateDateChange = (date) => {
    //   const dist = calcTotalPeriod();
    //   setCreateDate(date);
    //   setEndDate(new Date(date.setDate(date.getDate() + dist)));
    // };

    // const handleEndDateChange = (date) => {
    //   const dist = calcTotalPeriod();
    //   setEndDate(date);
    //   setCreateDate(new Date(date.setDate(date.getDate() - dist)));
    // };

    // useEffect(() => {
    //   const dist = calcTotalPeriod();
    //   const end = new Date(createDate);
    //   end.setDate(createDate.getDate() + dist);
    //   setEndDate(end);
    // }, [selectTicket]);

    return (
        <div
            className={`modal flex items-center justify-center
    ${props.isOpen ? 'block' : 'invisible'}`}
        >
            <div className="modal-dialog w-1/2 mx-auto" style={{width: '50%'}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>슬롯 추가</h2>
                        <button className="close" onClick={props.onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body flex flex-1">
                        <div className="flex flex-col flex-1 space-y-4">
                            {/* 대행사와 광고주 선택 */}
                            <div>
                                <div className="flex">
                                    <div className="p-2 bg-gray-100">
                                        {showTargetDropdown()}
                                    </div>
                                    <div className="p-2 bg-gray-100">
                                        <label className="m-2">시작일</label>
                                        <DatePicker
                                            id="createDate"
                                            selected={createDate}
                                            onChange={handleCreateDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            className="border text-center rounded py-2 w-full focus:outline-none focus:border-blue-500"
                                            popperClassName="rounded border shadow-md"
                                        />
                                    </div>
                                    <div className="p-2 bg-gray-100">
                                        <label className="m-2">종료일</label>
                                        <DatePicker
                                            id="endDate"
                                            selected={endDate}
                                            onChange={handleEndDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            className="border text-center rounded py-2 w-full focus:outline-none focus:border-blue-500"
                                            popperClassName="rounded border shadow-md"
                                        />
                                    </div>
                                </div>
                            </div>


                            {/* 기간(시작일) 선택 */}
                            <div className="flex">

                            </div>

                            <div className="flex space-y-4">
                                <div className="p-2 bg-gray-100">
                                    <label className="m-2">플랫폼</label>
                                    <PlatformDropdown
                                        userId={props.data.userId}
                                        onChange={getUserTicket}
                                    />
                                </div>
                            </div>

                            {/* 티켓 추가 */}
                            {/*<div className="flex">*/}
                            {/*    <table className="w-full mb-4 border rounded-[20px] shadow-xl">*/}
                            {/*        <thead className="bg-gray-500 text-gray-200">*/}
                            {/*        <tr>*/}
                            {/*            <th className="text-center border-b-4 border-gray-300 p-2">*/}
                            {/*                이름*/}
                            {/*            </th>*/}
                            {/*            <th className="text-center border-b-4 border-gray-300 p-2">*/}
                            {/*                기간*/}
                            {/*            </th>*/}
                            {/*            <th className="text-center border-b-4 border-gray-300 p-2">*/}
                            {/*                수량*/}
                            {/*            </th>*/}
                            {/*            <th className="text-center border-b-4 border-gray-300 p-2">*/}
                            {/*                가격*/}
                            {/*            </th>*/}
                            {/*        </tr>*/}
                            {/*        </thead>*/}
                            {/*        <tbody className="bg-gray-200">*/}
                            {/*        {userTicket.length !== 0 ? (*/}
                            {/*            userTicket.map((ticket, index) => (*/}
                            {/*                <>*/}
                            {/*                    <tr*/}
                            {/*                        className={`m-2 ${*/}
                            {/*                            index === selectedRow ? `bg-gray-300` : `bg-white`*/}
                            {/*                        }`}*/}
                            {/*                        onClick={() => handleRowClick(index)}*/}
                            {/*                    >*/}
                            {/*                        <td className="text-center border-b-4 border-gray-300 p-4">*/}
                            {/*                            {ticket.ticket.name}*/}
                            {/*                        </td>*/}
                            {/*                        <td className="text-center border-b-4 border-gray-300 p-4">*/}
                            {/*                            {ticket.ticket.period}*/}
                            {/*                        </td>*/}
                            {/*                        <td className="text-center border-b-4 border-gray-300 p-4">*/}
                            {/*                            {ticket.count}*/}
                            {/*                        </td>*/}
                            {/*                        <td className="text-center border-b-4 border-gray-300 p-4">*/}
                            {/*                            {ticket.ticket.price}*/}
                            {/*                        </td>*/}
                            {/*                    </tr>*/}
                            {/*                </>*/}
                            {/*            ))*/}
                            {/*        ) : (*/}
                            {/*            <tr className="m-2 bg-white">*/}
                            {/*                <td*/}
                            {/*                    colSpan="4"*/}
                            {/*                    className="text-center border-b-4 border-gray-300 p-4"*/}
                            {/*                >*/}
                            {/*                    <CancelButton*/}
                            {/*                        name="티켓이 없습니다."*/}
                            {/*                        onClick={() => {*/}
                            {/*                        }}*/}
                            {/*                    />*/}
                            {/*                </td>*/}
                            {/*            </tr>*/}
                            {/*        )}*/}
                            {/*        </tbody>*/}
                            {/*    </table>*/}
                            {/*</div>*/}

                            {/* 슬롯 갯수 선택 */}
                            <div className="p-2 mb-4 bg-gray-100">
                                <label className="m-2">슬롯 개수</label>
                                <input
                                    type="text"
                                    id="slot_cnt"
                                    name="slot_cnt"
                                    value={formData.slot_cnt}
                                    placeholder="슬롯 개수를 입력하세요."
                                    className="border rounded w-full py-2 px-3"
                                    onChange={handleChange}
                                />
                            </div>

                            {/* 키워드 */}
                            <div className="p-2 mb-4 bg-gray-100">
                                <label className="m-2">키워드</label>
                                <input
                                    type="text"
                                    id="keyword"
                                    name="keyword"
                                    value={formData.keyword}
                                    placeholder="키워드를 입력하세요."
                                    className="border rounded w-full py-2 px-3"
                                    onChange={handleChange}
                                />
                            </div>

                            {/* url */}
                            <div className="p-2 mb-4 bg-gray-100">
                                <label className="m-2">url</label>
                                <input
                                    type="text"
                                    id="url"
                                    name="url"
                                    value={formData.url}
                                    placeholder="url를 입력하세요."
                                    className="border rounded w-full py-2 px-3"
                                    onChange={handleChange}
                                />
                            </div>
                            {/* compare_url */}
                            <div className="p-2 mb-4 bg-gray-100">
                                <label className="m-2">compare_url</label>
                                <input
                                    type="text"
                                    id="compare_url"
                                    name="compare_url"
                                    value={formData.compare_url}
                                    placeholder="가격비교 url을 입력하세요."
                                    className="border rounded w-full py-2 px-3"
                                    onChange={handleChange}
                                />
                            </div>

                            {/* public memo */}
                            <div className="p-2 mb-4 bg-gray-100">
                                <label className="m-2">공개 메모</label>
                                <textarea
                                    type="text"
                                    id="public_memo"
                                    name="public_memo"
                                    value={formData.public_memo}
                                    rows="4"
                                    placeholder="공개 메모를 입력하세요."
                                    className="border rounded w-full py-2 px-3"
                                    onChange={handleChange}
                                />
                            </div>

                            {/* private memo */}
                            <div className="p-2 mb-4 bg-gray-100">
                                <label className="m-2">비공개 메모</label>
                                <textarea
                                    type="text"
                                    id="private_memo"
                                    name="private_memo"
                                    value={formData.private_memo}
                                    rows="4"
                                    placeholder="비공개 메모를 입력하세요."
                                    className="border rounded w-full py-2 px-3"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <AddButton name="추가" onClick={handleAddSlot}/>
                        <NormalButton name="취소" onClick={props.onClose}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddSlotModal;
