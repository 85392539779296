// src/components/Dashboard.js
import axios from 'axios';
import {React, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import AddButton from '../../common/button/AddButton';
import CancelButton from '../../common/button/CancelButton';
import Header from '../../common/Header';
import NormalButton from '../../common/button/NormalButton';
import UpdateButton from '../../common/UpdateButton';
import axiosInstance from "../../../shared/axiosInstance";
import OptionButton from "../../common/button/OptionButton";
import SlotFilterDropdown from "../../common/drop-down/SlotFilterDropdown";
import AddSlotModalExcel from "../../agency/slot/AddSlotModalExcel";

function MyPageManagement() {
    const location = useLocation();
    const navigate = useNavigate();
    const [userTickets, setUserTickets] = useState([]);
    const [tickets, setTickets] = useState(null);
    const [isAddSlotModalOpen, setAddSlotModalOpen] = useState(false);
    const [historyLogModalOpen, setHistoryLogModalOpen] = useState(false);
    const [rankLogModalOpen, setRankLogModalOpen] = useState(false);
    const [slotModalOpen, setSlotModalOpen] = useState(false);
    const [isAddSlotModalExcelOpen, setAddSlotModalExcelOpen] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState({
        advertiser: {},
        agency: {},
        created_at: '',
        end_date: '',
        id: '',
        keyword: '',
        platform: {},
        private_memo: '',
        public_memo: '',
        rank: '',
        slot_history: [],
        url: '',
        compare_url: '',
        userInfo: {},
    });
    const [selectUserInfo, setSelectUserInfo] = useState({
        userId: '',
        userType: '',
    });

    const token = localStorage.getItem('token');
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const userInfo = location.state?.userInfo || {};
    let [userId, setUserId] = useState(null);
    let [userType, setUserType] = useState(null);

    const getSlot = async (newPage, pageSize) => {
        const config = {
            headers: {
                'Content-Type': 'text/plain',
                Authorization: 'Bearer ' + token,
            },
        };

        axiosInstance
            .get(`/api/v1/ticket/agency/all`, config)
            .then((response) => response.data)
            .then((data) => {
                setUserTickets(data.agency_ticket_list);
            })
            .catch((error) => {
                if (error.response.statusText === 'Unauthorized') {
                    navigate('/');
                    localStorage.removeItem('token');
                    localStorage.removeItem('nickname');
                }
                console.log(error);
            });
    };

    const handlePageChange = async (newPage) => {
        if (newPage >= 1 && newPage <= totalPage) {
            getSlot(newPage, pageSize);
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPagesToShow = 5;

        for (let i = Math.max(1, page - Math.floor(maxPagesToShow / 2)); i <= Math.min(totalPage, page + Math.floor(maxPagesToShow / 2)); i++) {
            pageNumbers.push(
                <NormalButton name={i} onClick={() => handlePageChange(i)}/>
            );
        }

        return pageNumbers;
    };
    const handleSearch = (event) => {
        if (event.keyCode === 13) {
            getSlot();
        }
    };

    const handleAddUser = (newUser) => {
        const newId = Math.max(...tickets.map((ticket) => ticket.id)) + 1;
        setTickets([...tickets, {id: newId, ...newUser, status: 'active'}]);
        setAddSlotModalOpen(false);
    };

    const handleShowHistoryLog = (event) => {
        event.stopPropagation();
        setHistoryLogModalOpen(true);
    };

    const handleShowRankLog = (event) => {
        event.stopPropagation();
        setRankLogModalOpen(true);
    };

    const showStatus = (status) => {
        switch (status) {
            case "WAITED":
                return <OptionButton name={'대기중'}/>
            case 'INPROGRESS':
                return <AddButton name={'진행중'}/>
            case 'COMPLETED':
                return <UpdateButton name={'완료됨'}/>
        }
    }

    const handleFilter = (selectedOption) => {
        const config = {
            headers: {
                'Content-Type': 'text/plain',
                Authorization: 'Bearer ' + token,
            },
            params: {
                page: page,
            },
        };

        if (selectedOption !== '4') {
            config.params['slot_type'] = parseInt(selectedOption);
        }

        const userId = localStorage.getItem('slotUserId');
        const userType = localStorage.getItem('slotUserType');

        axiosInstance
            .get(`/api/v1/accounts/${userId}/slots/all`, config)
            .then((response) => response.data.items)
            .then((items) => {
                setUserTickets(items);
            })
            .catch((error) => {
                console.log(error);
                if (error.response.statusText === 'Unauthorized') {
                    navigate('/');
                    localStorage.removeItem('token');
                    localStorage.removeItem('nickname');
                }
            });
    };

    useEffect(() => {
        getSlot(page);
    }, []);

    return (
        <div className="p-5 min-h-screen">
            <Header name="티켓 잔여 목록 리스트"></Header>
            <div className="flex mb-5 m-3 justify-between">
                <div className="flex flex-1 space-x-10">
                    <label className="flex space-x-2 text-gray-600">
                        {/*<div className='flex items-center'>필터</div>*/}
                        {/*<SlotFilterDropdown onFilter={handleFilter}/>*/}
                    </label>
                    <label className="flex space-x-2 text-gray-600 flex-1 text-center">
                        {/*<div className='flex items-center'>검색</div>*/}
                        {/*<input*/}
                        {/*    type="text"*/}
                        {/*    id="searched"*/}
                        {/*    name="searched"*/}
                        {/*    placeholder="검색어를 입력하세요"*/}
                        {/*    className="border rounded w-[50%] py-2 px-3"*/}
                        {/*    onKeyDown={handleSearch}*/}
                        {/*/>*/}
                        {/*<UpdateButton name="검색" onClick={getSlot}/>*/}
                    </label>
                </div>

                {/*<div className="flex">*/}
                {/*    <AddButton*/}
                {/*        name="슬롯 추가 (엑셀)"*/}
                {/*        onClick={() => {*/}
                {/*            let data = {*/}
                {/*                userId: localStorage.getItem('slotUserId'),*/}
                {/*                userType: localStorage.getItem('slotUserType'),*/}
                {/*            };*/}
                {/*            setSelectUserInfo(data);*/}
                {/*            setAddSlotModalExcelOpen(true);*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    <AddButton*/}
                {/*        name="슬롯 추가"*/}
                {/*        onClick={() => {*/}
                {/*            let data = {*/}
                {/*                userId: localStorage.getItem('slotUserId'),*/}
                {/*                userType: localStorage.getItem('slotUserType'),*/}
                {/*            };*/}
                {/*            setSelectUserInfo(data);*/}
                {/*            setAddSlotModalOpen(true);*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
            <table className="w-full mb-5 border m-3 shadow-xl">
                <thead className="bg-gray-500 text-gray-200">
                <tr>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        ID
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        플랫폼
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        이름
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        기간
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        수량
                    </th>
                </tr>
                </thead>
                <tbody className="bg-gray-200 rounded">
                {userTickets.length !== 0 ? (
                    userTickets.map((ticket, index) => (
                        <tr
                            class="text-sm bg-gray-100 hover:bg-gray-200 active:bg-gray-300 border border-gray-300 py-1 px-2 rounded mr-2"
                            onClick={() => {
                                setSlotModalOpen(true);
                            }}
                            onMouseOver={() => {
                                let data = userTickets[index];
                                data['userId'] = localStorage.getItem('slotUserId');
                                data['userType'] = localStorage.getItem('slotUserType');
                                setSelectedSlot(data);
                            }}
                            onTouchStart={() => {
                                let data = userTickets[index];
                                data['userId'] = localStorage.getItem('slotUserId');
                                data['userType'] = localStorage.getItem('slotUserType');
                                setSelectedSlot(data);
                            }}
                        >
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {ticket.ticket.id}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {ticket.ticket.platform.name}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {ticket.ticket.name}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {ticket.ticket.period}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {ticket.count}
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr class="border p-2">
                        <CancelButton name="슬롯이 없습니다."/>
                    </tr>
                )}
                </tbody>
            </table>
            {/*<div className='flex justify-center'>*/}
            {/*    {renderPageNumbers()}*/}
            {/*</div>*/}

            <AddSlotModalExcel
                users={userTickets}
                setUsers={setUserTickets}
                isOpen={isAddSlotModalExcelOpen === true}
                onClose={() => {
                    setAddSlotModalExcelOpen(false);
                }}
                data={selectUserInfo}
                onAddUser={handleAddUser}
            />

        </div>
    );
}

export default MyPageManagement;
