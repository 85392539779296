import React, {useEffect} from "react";

export function TextField(props) {
    const {labelName, readOnly, id, name, value, onChange} = props;
    
    return (
        <div className={`flex space-x-2 flex-1 items-center ${readOnly ? 'bg-gray-300' : 'bg-gray-100'}`}>
            <div className="p-2 w-full">
                <label className="m-2">{labelName}</label>
                <input
                    type="text"
                    readOnly={readOnly}
                    id={id}
                    name={name}
                    value={value}
                    className="border rounded w-full py-2 px-3"
                    onChange={onChange}
                />
            </div>
        </div>
    );
}