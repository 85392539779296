// src/components/Dashboard.js
import axios from 'axios';
import {React, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import AddButton from '../../common/button/AddButton';
import AddPlatformModal from './AddPlatformModal';
import AddTicketModal from './AddTicketModal';
import Header from '../../common/Header';
import PlatformModal from './PlatformModal';
import TicketModal from './TicketModal';
import axiosInstance from "../../../shared/axiosInstance";
import CancelButton from "../../common/button/CancelButton";

function TicketManagement() {
    const navigate = useNavigate();
    const [tickets, setTickets] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [isAddTicketModalOpen, setAddTicketModalOpen] = useState();
    const [isAddPlatformModalOpen, setAddPlatformModalOpen] = useState();
    const [isTicketModalOpen, setTicketModalOpen] = useState();
    const [isPlatformModalOpen, setPlatformModalOpen] = useState();
    const [selectedTicket, setSelectedTicket] = useState({
        name: '',
        period: '',
        price: '',
        platform: '',
    });
    const [selectedPlatform, setSelectedPlatform] = useState({
        name: '',
        description: '',
    });
    const token = localStorage.getItem('token');
    const page = 1;

    const getTicket = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };

            const response = await axiosInstance.get('/api/v1/ticket/all', config);
            setTickets(response.data.ticket_list);
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }
            console.log(error);
        }
    };

    const getPlatform = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };

            const response = await axiosInstance.get('/api/v1/platform/all', config);
            setPlatforms(response.data.platform_list);
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }
            console.log(error);
        }
    };

    const handleTicketChange = (element) => {
        const newId = Math.max(...tickets.map((ticket) => ticket.id)) + 1;
        setTickets([...tickets, {id: newId, ...element, status: 'active'}]);
        setTicketModalOpen(false);
    };

    const handlePlatformChange = (element) => {
        const newId = Math.max(...tickets.map((ticket) => ticket.id)) + 1;
        setTickets([...tickets, {id: newId, ...element, status: 'active'}]);
        setTicketModalOpen(false);
    };


    useEffect(() => {
        getTicket();
        getPlatform();
    }, []);

    return (
        <div className="p-5 min-h-screen">
            <div className="flex place-content-between mb-5 m-3">
                <div className="flex">
                    <Header name="티켓 리스트"></Header>
                </div>
                <div className="flex">
                    <AddButton
                        name="티켓 추가"
                        onClick={() => {
                            setAddTicketModalOpen(true);
                        }}
                    />
                </div>
            </div>
            <table className="w-full mb-5 border m-3 shadow-xl">
                <thead className="bg-gray-500 text-gray-200">
                <tr>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        플랫폼 정보
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        이름
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        기간
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        가격
                    </th>
                </tr>
                </thead>
                <tbody className="bg-gray-200">
                {tickets.length != 0 ? (
                    tickets.map((ticket) => (
                        <tr
                            className="bg-gray-100 hover:bg-gray-200 active:bg-gray-300 border border-gray-300 py-1 px-2 rounded mr-2"
                            onClick={() => {
                                setSelectedTicket(ticket);
                                setTicketModalOpen(true);
                            }}
                        >
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {ticket.platform.name}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {ticket.name}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {ticket.period}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {ticket.price}
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr className="border p-2">
                        <CancelButton name="티켓이 없습니다."/>
                    </tr>
                )}
                </tbody>
            </table>
            <div className="flex place-content-between mb-5 m-3">
                <div className="flex">
                    <Header name="플랫폼 리스트"></Header>
                </div>
                <div className="flex">
                    <AddButton
                        name="플랫폼 추가"
                        onClick={() => {
                            setAddPlatformModalOpen(true);
                        }}
                    />
                </div>
            </div>
            <table className="w-full mb-5 border m-3 shadow-xl">
                <thead className="bg-gray-500 text-gray-200">
                <tr>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        이름
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        정보
                    </th>
                </tr>
                </thead>
                <tbody className="bg-gray-200">
                {platforms.length != 0 ? (
                    platforms.map((platform) => (
                        <tr
                            className="bg-gray-100 hover:bg-gray-200 active:bg-gray-300 border border-gray-300 py-1 px-2 rounded mr-2"
                            onClick={() => {
                                setSelectedPlatform(platform);
                                setPlatformModalOpen(true);
                            }}
                        >
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {platform.name}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {platform.description}
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr className="border p-2">
                        <CancelButton name="플랫폼가 없습니다."/>
                    </tr>
                )}
                </tbody>
            </table>
            <AddTicketModal
                users={tickets}
                setUsers={setTickets}
                isOpen={isAddTicketModalOpen === true}
                onClose={() => {
                    setAddTicketModalOpen(false);
                }}
                onAddUser={handleTicketChange}
            />
            <AddPlatformModal
                users={platforms}
                setUsers={setPlatforms}
                isOpen={isAddPlatformModalOpen === true}
                onClose={() => {
                    setAddPlatformModalOpen(false);
                }}
                onAddUser={handlePlatformChange}
            />
            <TicketModal
                isOpen={isTicketModalOpen === true}
                onClose={() => {
                    setTicketModalOpen(false);
                }}
                onAddUser={handleTicketChange}
                data={selectedTicket}
            />
            <PlatformModal
                isOpen={isPlatformModalOpen === true}
                onClose={() => {
                    setPlatformModalOpen(false);
                }}
                onAddUser={handlePlatformChange}
                data={selectedPlatform}
            />
        </div>
    );
}

export default TicketManagement;
