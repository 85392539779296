import axios from 'axios';
import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useNavigate} from 'react-router-dom';
import '../../../style/AddUserModal.css';
import AddButton from '../../common/button/AddButton';
import CancelButton from '../../common/button/CancelButton';
import NormalButton from '../../common/button/NormalButton';
import UpdateButton from '../../common/UpdateButton';
import axiosInstance from "../../../shared/axiosInstance";
import {TextField} from "../Editor/TextField";
import {DatePickerInput} from "../Editor/DatePickerInput";
import {TextArea} from "../Editor/TextArea";

function AllSlotModal(props) {
    const {
        userType, isOpen, onClose,
        handleUpdateSlotAll, handleDeleteSlotAll, handleInActiveSlotAll,
        extraFieldNames
    } = props;
    const navigate = useNavigate();
    const [keyword, setKeyword] = useState();
    const [url, setUrl] = useState();
    const [compareUrl, setCompareUrl] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [publicMemo, setPublicMemo] = useState();
    const [privateMemo, setPrivateMemo] = useState();
    const [extraField1, setExtraField1] = useState();
    const [extraField3, setExtraField3] = useState();
    const [extraField5, setExtraField5] = useState();
    const [extraField2, setExtraField2] = useState();
    const [extraField4, setExtraField4] = useState();
    const [extraField6, setExtraField6] = useState();
    const token = localStorage.getItem('token');

    const updateUser = async () => {
        const data = {};

        if (startDate) {
            data.start_date = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`;
        }

        if (endDate) {
            data.end_date = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}`;
        }

        if (keyword) {
            data.keyword = keyword;
        }

        if (url) {
            data.url = url;
        }

        if (compareUrl) {
            data.compare_url = compareUrl || '';
        }

        if (privateMemo) {
            data.private_memo = privateMemo;
        }

        if (publicMemo) {
            data.public_memo = publicMemo;
        }

        if (extraField1) {
            data.extra_field_1 = extraField1;
        }

        if (extraField3) {
            data.extra_field_3 = extraField3;
        }

        if (extraField5) {
            data.extra_field_5 = extraField5;
        }

        if (extraField2) {
            data.extra_field_2 = extraField2;
        }

        if (extraField4) {
            data.extra_field_4 = extraField4;
        }

        if (extraField6) {
            data.extra_field_6 = extraField6;
        }

        handleUpdateSlotAll(data);
    };


    useEffect(() => {
    }, [isOpen]);

    return (
        <div className={`modal flex items-center justify-center ${isOpen ? 'block' : 'invisible'}`}>
            <div className="modal-dialog w-1/2 mx-auto" style={{width: '70%'}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>슬롯 정보</h2>
                        <button className="close" onClick={onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="grid grid-cols-1 gap-4">
                            <div className="flex flex-col space-y-2">
                                <TextField labelName={"키워드"} id={"keyword"} name={"keyword"} value={keyword}
                                           onChange={(e) => setKeyword(e.target.value)}/>
                                <TextField labelName={"상품링크"} id={"url"} name={"url"} value={url}
                                           onChange={(e) => setUrl(e.target.value)}/>
                                <TextField labelName={"가격 비교 상품 링크"} id={"compare_url"} name={"compare_url"}
                                           value={compareUrl} onChange={(e) => setCompareUrl(e.target.value)}/>
                                <div className="flex space-x-2 flex-1">
                                    {userType !== 'advertiser' ? (
                                        <DatePickerInput labelName={"시작일"} id={"createdAt"} date={startDate}
                                                         setDate={setStartDate}/>) : (<></>)}
                                    {userType === 'admin' ? (
                                        <DatePickerInput labelName={"종료일"} id={"endDate"} date={endDate}
                                                         setDate={setEndDate}/>) : (<></>)}
                                </div>
                            </div>

                            {/* 3 */}
                            <TextArea labelName={"공개 메모"} rows={4} id={"public_memo"} name={"public_memo"}
                                      value={publicMemo} onChange={(e) => setPublicMemo(e.target.value)}/>
                            {/* 4 */}
                            {userType !== 'advertiser' ? (
                                    <TextArea labelName={"비공개 메모"} rows={4} id={"private_memo"} name={"private_memo"}
                                              value={privateMemo} onChange={(e) => setPrivateMemo(e.target.value)}/>)
                                : (<></>)}

                            <div className="grid grid-cols-2 gap-4">
                                <div className="flex flex-col space-y-2">
                                    <TextField labelName={extraFieldNames?.extra_field_1_name ?? ''}
                                               id={"extra_filed_1"}
                                               name={"extra_field_1"} value={extraField1}
                                               onChange={(e) => setExtraField1(e.target.value)}/>
                                    <TextField labelName={extraFieldNames?.extra_field_3_name ?? ''}
                                               id={"extra_filed_3"}
                                               name={"extra_field_3"} value={extraField3}
                                               onChange={(e) => setExtraField3(e.target.value)}/>
                                    <TextField labelName={extraFieldNames?.extra_field_5_name ?? ''}
                                               id={"extra_filed_5"}
                                               name={"extra_field_5"} value={extraField5}
                                               onChange={(e) => setExtraField5(e.target.value)}/>
                                </div>
                                <div className="flex flex-col space-y-2">
                                    <TextField labelName={extraFieldNames?.extra_field_2_name ?? ''}
                                               id={"extra_filed_2"}
                                               name={"extra_field_2"} value={extraField2}
                                               onChange={(e) => setExtraField2(e.target.value)}/>
                                    <TextField labelName={extraFieldNames?.extra_field_4_name ?? ''}
                                               id={"extra_filed_4"}
                                               name={"extra_field_4"} value={extraField4}
                                               onChange={(e) => setExtraField4(e.target.value)}/>
                                    <TextField labelName={extraFieldNames?.extra_field_6_name ?? ''}
                                               id={"extra_filed_6"}
                                               name={"extra_field_6"} value={extraField6}
                                               onChange={(e) => setExtraField6(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <div class="flex">
                            <UpdateButton name="정보 수정" onClick={updateUser}/>
                        </div>
                        {userType === 'admin' ?
                            (<>
                                    <div className="flex">
                                        <CancelButton name="비활성화" onClick={handleInActiveSlotAll}/>
                                    </div>
                                    <div className="flex">
                                        <CancelButton name="삭제" onClick={handleDeleteSlotAll}/>
                                    </div>
                                </>
                            ) : (<></>)
                        }
                        <div class="flex">
                            <NormalButton name="취소" onClick={onClose}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllSlotModal;
