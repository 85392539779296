import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useNavigate} from 'react-router-dom';
import axiosInstance from '../../../shared/axiosInstance';
import '../../../style/AddUserModal.css';
import AddButton from '../../common/button/AddButton';
import AdvertiserDropdown from '../../common/drop-down/AdvertiserDropdown';
import AgencyDropdown from '../../common/drop-down/AgencyDropdown';
import CancelButton from '../../common/button/CancelButton';
import NormalButton from '../../common/button/NormalButton';
import PlatformDropdown from '../../common/drop-down/PlatformDropdown';
import PlatformDropdownExcel from "../../common/drop-down/PlatformDropdownExcel";
import UpdateButton from "../../common/UpdateButton";

function AddSlotModalExcel(props) {
    const navigate = useNavigate();
    const [userTicket, setUserTicket] = useState([]);
    const [selectedRow, setSelectedRow] = useState(-1);
    const [createDate, setCreateDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [formData, setFormData] = useState({
        slot_cnt: '',
        keyword: '',
        url: '',
        compare_url: '',
        private_memo: '',
        public_memo: '',
    });
    const [file, setFile] = useState(null);
    const [selectTicket, setSelectTicket] = useState({
        name: '',
        period: '',
        price: ''
    });

    const token = localStorage.getItem('token');
    // const [selectTicket, setSelectTicket] = useState([]);


    const getUserTicket = async (userId, platformId) => {
        try {
            if (userId === '' || platformId === '') {
                return;
            }

            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };

            const response = await axiosInstance.get(
                `/api/v1/accounts/${userId}/platform/${platformId}/ticket/detail`,
                config
            );

            setUserTicket(response.data.user_ticket_list);
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }
            console.log(error);
        }
    };

    const handleRowClick = (index) => {
        if (selectedRow === index) {
            setSelectedRow(-1);
            setEndDate(createDate);
        } else {
            setSelectedRow(index);
            const tempEndDate = new Date(Date.UTC(createDate.getFullYear(), createDate.getMonth(), createDate.getDate()));
            tempEndDate.setUTCDate(tempEndDate.getUTCDate() + userTicket[index].ticket.period - 1);
            setEndDate(tempEndDate);
        }
    };

    const calcTotalPeriod = () => {
        return selectedRow === -1 ? 0 : userTicket[selectedRow].ticket.period;
    };

    const handleCreateDateChange = (date) => {
        const dist = calcTotalPeriod();
        setCreateDate(date);
        const tempEndDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        tempEndDate.setUTCDate(tempEndDate.getDate() + dist);
        setEndDate(tempEndDate);
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    }

    const handleUpload = async () => {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + token,
            },
        };

        const formData = new FormData();
        formData.append('platform_id', parseInt(document.getElementById('selectedPlatformType').value));
        formData.append('start_date', `${createDate.getFullYear()}-${String(createDate.getMonth() + 1).padStart(2, '0')}-${String(createDate.getDate()).padStart(2, '0')}`);
        formData.append('end_date', `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}`);
        formData.append('advertiser_id', parseInt(localStorage.getItem('slotUserId')));
        formData.append('used_ticket_id', parseInt(userTicket[selectedRow].ticket.id));
        formData.append('platform_id', parseInt(document.getElementById('selectedPlatformTypeExcel').value));
        formData.append('excel_file', file);

        axiosInstance
            .post(`/api/v1/slots/excel/agency/create`, formData, config)
            .then((response) => {
                window.location.reload();
                props.onClose();
            })
            .catch((error) => {
                // if (error.response.statusText === 'Unauthorized') {
                //     navigate('/');
                //     localStorage.removeItem('token');
                //     localStorage.removeItem('nickname');
                // }
                console.log(error);
            })

    }

    return (
        <div
            className={`modal flex items-center justify-center
    ${props.isOpen ? 'block' : 'invisible'}`}
        >
            <div className="modal-dialog w-1/2 mx-auto" style={{width: '50%'}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>슬롯 추가</h2>
                        <button className="close" onClick={props.onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body flex flex-1">
                        <div className="flex flex-col flex-1 space-y-4">
                            {/* 대행사와 광고주 선택 */}
                            <div className='flex flex-col flex-1'>
                                <div className="flex space-x-2">

                                    <div className="p-2 bg-gray-100">
                                        <label className="m-2">플랫폼</label>
                                        <PlatformDropdownExcel
                                            userId={localStorage.getItem('user_id')}
                                            onChange={getUserTicket}
                                        />
                                    </div>
                                    <div className="p-2 bg-gray-100">
                                        <label className="m-2">시작일</label>
                                        <DatePicker
                                            id="createDate"
                                            selected={createDate}
                                            onChange={handleCreateDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            className="border text-center rounded py-2 w-full focus:outline-none focus:border-blue-500"
                                            popperClassName="rounded border shadow-md"
                                        />
                                    </div>
                                    <div className="p-2 bg-gray-100">
                                        <label className="m-2">종료일</label>
                                        <DatePicker
                                            id="endDate"
                                            selected={endDate}
                                            dateFormat="yyyy-MM-dd"
                                            className="border text-center rounded py-2 w-full focus:outline-none focus:border-blue-500"
                                            popperClassName="rounded border shadow-md"
                                        />
                                    </div>
                                </div>

                                {/* 티켓 추가 */}
                                <div className="flex">
                                    <table className="w-full mb-4 border rounded-[20px] shadow-xl overflow-y-auto">
                                        <thead className="bg-gray-500 text-gray-200">
                                        <tr>
                                            <th className="text-center border-b-4 border-gray-300 p-2">
                                                이름
                                            </th>
                                            <th className="text-center border-b-4 border-gray-300 p-2">
                                                기간
                                            </th>
                                            <th className="text-center border-b-4 border-gray-300 p-2">
                                                수량
                                            </th>
                                            <th className="text-center border-b-4 border-gray-300 p-2">
                                                가격
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="bg-gray-200">
                                        {userTicket.length !== 0 ? (
                                            userTicket.map((ticket, index) => (
                                                <>
                                                    <tr
                                                        className={`m-2 ${
                                                            index === selectedRow ? `bg-gray-300` : `bg-white`
                                                        }`}
                                                        onClick={() => handleRowClick(index)}
                                                    >
                                                        <td className="text-center border-b-4 border-gray-300 p-4">
                                                            {ticket.ticket.name}
                                                        </td>
                                                        <td className="text-center border-b-4 border-gray-300 p-4">
                                                            {ticket.ticket.period}
                                                        </td>
                                                        <td className="text-center border-b-4 border-gray-300 p-4">
                                                            {ticket.count}
                                                        </td>
                                                        <td className="text-center border-b-4 border-gray-300 p-4">
                                                            {ticket.ticket.price}
                                                        </td>
                                                    </tr>
                                                </>
                                            ))
                                        ) : (
                                            <tr className="m-2 bg-white">
                                                <td
                                                    colSpan="4"
                                                    className="text-center border-b-4 border-gray-300 p-4"
                                                >
                                                    <CancelButton
                                                        name="티켓이 없습니다."
                                                        onClick={() => {
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div className="flex flex-col space-y-2 space-x-1 p-2 bg-gray-100">
                                <label className="m-2">파일 업로드</label>
                                <input className='bg-white' type="file" onChange={handleFileChange}/>
                            </div>

                        </div>
                    </div>
                    <div className="flex justify-end">
                        <UpdateButton name="엑셀 파일 양식" onClick={() => {
                            window.open('https://docs.google.com/spreadsheets/d/1GMHtzMgl8kUjuqJdGQco724RyRy7MxgNJ5qTJFnhir0/edit#gid=0');
                        }}/>
                        <AddButton name="추가" onClick={handleUpload}/>
                        <NormalButton name="취소" onClick={props.onClose}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddSlotModalExcel;
