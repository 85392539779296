// src/components/AddUserModal.js
import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useNavigate} from 'react-router-dom';
import axiosInstance from '../../../shared/axiosInstance';
import '../../../style/AddUserModal.css';
import AddButton from '../../common/button/AddButton';
import AdvertiserDropdown from '../../common/drop-down/AdvertiserDropdown';
import AgencyDropdown from '../../common/drop-down/AgencyDropdown';
import CancelButton from '../../common/button/CancelButton';
import NormalButton from '../../common/button/NormalButton';
import PlatformDropdown from '../../common/drop-down/PlatformDropdown';
import PlatformDropdownExcel from "../../common/drop-down/PlatformDropdownExcel";
import UpdateButton from "../../common/UpdateButton";

function AddSlotModalExcel(props) {
    const navigate = useNavigate();
    const [userTicket, setUserTicket] = useState([]);
    const [selectedRow, setSelectedRow] = useState(-1);
    const [createDate, setCreateDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [formData, setFormData] = useState({
        slot_cnt: '',
        keyword: '',
        url: '',
        compare_url:'',
        private_memo: '',
        public_memo: '',
    });
    const [file, setFile] = useState(null);

    const token = localStorage.getItem('token');
    // const [selectTicket, setSelectTicket] = useState([]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleAddSlot = () => {
        // if (formData.slot_cnt &&
        //     formData.keyword &&
        //     formData.url &&
        //     formData.private_memo &&
        //     formData.public_memo &&
        //     document.getElementById('selectedPlatformType').value &&
        //     document.getElementById('selectedAdvertiser').value &&
        //     document.getElementById('selectedAgency').value
        // ) {
        //     addSlot();
        //     props.onClose();
        // }
        addSlot();
        props.onClose();
    };

    const addSlot = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };

            let data = formData;
            data['start_date'] = `${createDate.getFullYear()}-${String(createDate.getMonth() + 1).padStart(2, '0')}-${String(createDate.getDate()).padStart(2, '0')}`;
            data['end_date'] = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}`;
            data['platform_id'] = parseInt(document.getElementById('selectedPlatformType').value);
            data['slot_cnt'] = parseInt(data['slot_cnt']);

            switch (parseInt(props.data.userType)) {
                case 1:
                    data['agency_id'] = parseInt(props.data.userId);
                    data['advertiser_id'] = parseInt(document.getElementById('selectedAdvertiser').value);
                    break;
                case 2:
                    data['agency_id'] = parseInt(document.getElementById('selectedAgency').value);
                    data['advertiser_id'] = parseInt(props.data.userId);
                    break;
            }


            axiosInstance
                .post(`/api/v1/accounts/${props.data.userId}/slots/admin/create`, data, config)
                .then((response) => {
                    window.location.reload();
                    props.onClose();
                })
        } catch (error) {
            if (error.response === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }

            console.log(error);
            props.onClose();
        }
    };

    const showTargetDropdown = () => {
        switch (parseInt(props.data.userType)) {
            case 1:
                return (
                    <>
                        <label className="m-2">광고주</label>
                        <AdvertiserDropdown/>
                    </>
                );
            case 2:
                return (
                    <>
                        <label className="m-2">대행사</label>
                        <AgencyDropdown/>
                    </>
                );
        }
    };

    const getUserTicket = async (userId, platformId) => {
        try {
            if (userId === '' || platformId === '') {
                return;
            }

            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };

            const response = await axiosInstance.get(
                `/api/v1/accounts/${userId}/platform/${platformId}/ticket/detail`,
                config
            );
            setUserTicket(response.data.user_ticket_list);
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }
            console.log(error);
        }
    };

    const handleRowClick = (index) => {
        if (selectedRow === index) {
            setSelectedRow(-1);
        } else {
            setSelectedRow(index);
        }
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    }

    const handleUpload = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token,
                },
            };

            const formData = new FormData();

            switch (parseInt(props.data.userType)) {
                case 1:
                    formData.append('agency_id', parseInt(props.data.userId));
                    formData.append('advertiser_id', parseInt(document.getElementById('selectedAdvertiser').value));
                    break;
                case 2:
                    formData.append('agency_id', parseInt(document.getElementById('selectedAgency').value));
                    formData.append('advertiser_id', parseInt(props.data.userId));
                    break;
            }

            formData.append('platform_id', parseInt(document.getElementById('selectedPlatformTypeExcel').value));
            formData.append('excel_file', file);

            axiosInstance
                .post(`/api/v1/slots/excel/admin/create`, formData, config)
                .then((response) => {
                    window.location.reload();
                    props.onClose();
                })
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }
            console.log(error);
        }
    }

    return (
        <div
            className={`modal flex items-center justify-center
    ${props.isOpen ? 'block' : 'invisible'}`}
        >
            <div className="modal-dialog w-1/2 mx-auto" style={{width: '50%'}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>슬롯 추가</h2>
                        <button className="close" onClick={props.onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body flex flex-1">
                        <div className="flex flex-col flex-1 space-y-4">
                            {/* 대행사와 광고주 선택 */}
                            <div className='flex flex-1'>
                                <div className="flex space-x-2">
                                    <div className="p-2 bg-gray-100">
                                        {showTargetDropdown()}
                                    </div>
                                    <div className="p-2 bg-gray-100">
                                        <label className="m-2">플랫폼</label>
                                        <PlatformDropdownExcel
                                            userId={props.data.userId}
                                            onChange={getUserTicket}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="flex flex-col space-y-2 space-x-1 p-2 bg-gray-100">
                                <label className="m-2">파일 업로드</label>
                                <input className='bg-white' type="file" onChange={handleFileChange}/>
                            </div>

                        </div>
                    </div>
                    <div className="flex justify-end">
                        <UpdateButton name="엑셀 파일 양식" onClick={() => {
                            window.open('https://docs.google.com/spreadsheets/d/1bvTY8JrOf6h32tNEY2t3-JCkHIkuQDqDh_w8kfXT91I/edit#gid=0')

                        }}/>
                        <AddButton name="추가" onClick={handleUpload}/>
                        <NormalButton name="취소" onClick={props.onClose}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddSlotModalExcel;
