// src/components/Dashboard.js
import axios from 'axios';
import {React, useEffect, useState} from 'react';

import {useNavigate} from 'react-router-dom';
import AddButton from '../../common/button/AddButton';
import AddUserModal from './AddUserModal';
import CancelButton from '../../common/button/CancelButton';
import FilterDropdown from '../../common/drop-down/FilterDropdown';
import GiveTicketModal from './GiveTicketModal';
import Header from '../../common/Header';
import NormalButton from '../../common/button/NormalButton';
import UpdateButton from '../../common/UpdateButton';
import UserModal from './UserModal';
import axiosInstance from "../../../shared/axiosInstance";
import {renderPageNumbers, showUserType} from "../../common/util/Util";
import {PageInfo} from "../Page/PageInfo";
import PageSizeFilterDropdown from "../drop-down/PageSizeFilterDropdown";
import ErrorModal from "../ErrorModal";

function UserManagement(props) {
    const navigate = useNavigate();
    const [users, setUsers] = useState(null);
    const [filteredUsers, setFiltered] = useState([]);
    const [isGiveTicketModelOpen, setIsGiveTicketModalOpen] = useState(false);
    const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
    const [isUserModalOpen, setUserModalOpen] = useState(false);
    const [isAddTicketModalOpen, setAddTicketModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState({
        id: '',
        name: '',
        nickname: '',
        password: '',
        confirmPassword: '',
    });
    const token = localStorage.getItem('token');

    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [selectedOption, setSelectedOption] = useState(null);

    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    const openModal = () => {
        setIsErrorModalOpen(true);
    };

    const closeModal = () => {
        setIsErrorModalOpen(false);
    };

    async function getUsers() {
        const config = {
            headers: {
                'Content-Type': 'text/plain',
                Authorization: 'Bearer ' + token,
            },
            params: {
                page: page,
                page_size: pageSize,
                searched: document.getElementById('searched').value,
            },
        };

        if (selectedOption !== '4') {
            config.params['user_type'] = selectedOption;
        }

        axiosInstance
            .get('/api/v1/accounts/all', config)
            .then((response) => {
                setFiltered(response?.data?.items ?? []);
                setUsers(response?.data?.items ?? []);
                setTotalPage(Math.ceil(response.data.total_count / response.data.page_size));
            })
            .catch((error) => {
                if (error.response.statusText === 'Unauthorized') {
                    navigate('/');
                    localStorage.removeItem('token');
                    localStorage.removeItem('nickname');
                }
                setErrorMessage(error.response.data.detail);
                setFiltered([]);
                setUsers([]);
                openModal();
                console.log(error);
            });
    };

    const handleSearch = (event) => {
        if (event.keyCode === 13) {
            getUsers();
        }
    };

    const handleAddTicket = (event) => {
        event.stopPropagation();
        setAddTicketModalOpen(true);
    };

    const handleShowSlot = (event) => {
        event.stopPropagation();
        localStorage.setItem('slotUserId', selectedUser.id);
        localStorage.setItem('slotUserType', selectedUser.user_type);
        localStorage.setItem('agency_user_id', selectedUser.agency_user_id);
        navigate(`/${props.userType}/slot-board`);
    };


    const slotExcelDownload = () => {
        const config = {
            headers: {
                'Content-Type': 'application/vnd.ms-excel',
                Authorization: 'Bearer ' + token,
            },
            responseType: 'blob',
        };

        axiosInstance
            .get('/api/v1/slots/excel/download', config)
            .then((response) => {
                const blob = new Blob([response.data], {
                    type: "application/vnd.ms-excel"
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${props.userType}-slot.xls`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            });
    }

    const generateManagementModal = (userType) => {
        const components = [
            <AddUserModal
                users={users}
                setUsers={setUsers}
                isOpen={isAddUserModalOpen === true}
                onClose={() => {
                    setAddUserModalOpen(false);
                }}
                data={selectedUser}
                userType={props.userType}
            />,
            <UserModal
                isOpen={isUserModalOpen === true}
                onClose={() => {
                    setUserModalOpen(false);
                }}
                data={selectedUser}
                setData={setSelectedUser}
            />,
            <ErrorModal
                isOpen={isErrorModalOpen === true}
                onClose={closeModal}
                message={errorMessage}
            />
        ]

        switch (userType) {
            case 'admin':
                components.push(<>
                    <GiveTicketModal
                        data={selectedUser}
                        isOpen={isAddTicketModalOpen === true}
                        onClose={() => {
                            setAddTicketModalOpen(false);
                        }}
                    />
                </>);
                break;
        }

        return <>{components}</>
    }


    useEffect(() => {
        getUsers();
    }, [page, pageSize, selectedOption]);

    return (
        <div className="p-5 min-h-screen">
            <Header name="유저 목록 리스트"></Header>
            <div className="flex place-content-between mb-5 m-3">
                <div className="flex space-x-1 w-full justify-between">
                    <label className="flex space-x-2 text-gray-600">
                        <div className='flex items-center'>페이지당</div>
                        <PageSizeFilterDropdown
                            setPage={setPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}/>
                    </label>
                    <label className="flex space-x-2 text-gray-600">
                        <div className='flex items-center'>필터</div>
                        <FilterDropdown
                            userType={props.userType}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}/>
                    </label>
                    <label className="flex space-x-2 text-gray-600 flex-1 text-center">
                        <div className='flex items-center'>검색</div>
                        <input
                            type="text"
                            id="searched"
                            name="searched"
                            placeholder="검색어를 입력하세요"
                            className="border rounded w-[50%] py-2 px-3"
                            onKeyDown={handleSearch}
                        />
                        <UpdateButton name="검색" onClick={getUsers}/>
                    </label>
                    <label className="text-gray-600">
                        <UpdateButton
                            name="전체 슬롯 리스트 다운로드"
                            onClick={() => {
                                slotExcelDownload();
                            }}
                        />
                    </label>

                    <label className="text-gray-600">
                        <AddButton
                            name="유저 추가"
                            onClick={() => {
                                setAddUserModalOpen(true);
                            }}
                        />
                    </label>

                </div>

                <div className="flex"></div>
            </div>
            <table className="w-full mb-5 border m-3 shadow-xl rounded">
                <thead className="bg-gray-500 text-gray-200">
                <tr className="rounded">
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        유저 구분
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">이름</th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">ID</th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        계정 생성일
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        잔여 티켓
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">
                        슬롯 상태
                    </th>
                    <th className="text-center  border-b-4 border-gray-300 p-4">옵션</th>
                </tr>
                </thead>
                <tbody className="bg-gray-200 rounded">
                {filteredUsers.length !== 0 ? (
                    filteredUsers.map((user) => (
                        <tr
                            className="text-sm bg-gray-100 hover:bg-gray-200 active:bg-gray-300 border border-gray-300 py-1 px-2 rounded mr-2"
                            onClick={() => {
                                const data = user;
                                data['password'] = '';
                                data['confirm_password'] = '';
                                setSelectedUser(data);
                                setUserModalOpen(true);
                            }}
                        >
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {showUserType(user.user_type)}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {user.name}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {user.nickname}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {user.created_at}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {user.tickets.length !== 0 ? (
                                    user.tickets.map((ticket) => (
                                        <NormalButton
                                            name={`${ticket.platform_ticket_name} : ${ticket.count}`}
                                        />
                                    ))
                                ) : (
                                    <NormalButton name="티켓이 없습니다."/>
                                )}
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                <NormalButton name={`대기 : ${user.wait_slot_cnt}`}/>
                                <NormalButton name={`진행 : ${user.progress_slot_cnt}`}/>
                                <NormalButton name={`완료 : ${user.completed_slot_cnt}`}/>
                                <NormalButton name={`비활성화 : ${user.inactive_slot_cnt}`}/>
                            </td>
                            <td className="text-center border-b-4 border-gray-300 p-4">
                                {user.user_type === 1 ? (
                                        <UpdateButton
                                            name="티켓 추가"
                                            onMouseOver={() => {
                                                setSelectedUser(user);
                                            }}
                                            onTouchStart={() => {
                                                setSelectedUser(user);
                                            }}
                                            onClick={handleAddTicket}
                                        />)
                                    : (
                                        <></>
                                    )}
                                <UpdateButton
                                    name="슬롯 정보"
                                    onMouseOver={() => {
                                        setSelectedUser(user);
                                    }}
                                    onTouchStart={() => {
                                        setSelectedUser(user);
                                    }}
                                    onClick={handleShowSlot}
                                />
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr className="border p-2">
                        <CancelButton name="유저가 없습니다."/>
                    </tr>
                )}
                </tbody>
            </table>
            <PageInfo page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize}
                      totalPage={totalPage}/>

            <div>
                {generateManagementModal(props.userType)}
            </div>
        </div>
    );
}

export default UserManagement;
