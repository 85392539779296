import React from "react";

export function TextArea(props) {
    const {labelName, rows, id, name, value, onChange} = props;
    return (
        <div className="flex">
            <div className="flex flex-1 space-x-2">
                <div className="flex flex-1">
                    <div className="p-2 bg-gray-100 flex flex-col flex-1">
                        <label className="m-2">{labelName}</label>
                        <textarea
                            id={id}
                            name={name}
                            value={value}
                            rows={rows}
                            placeholder="내용을 입력하세요"
                            className="border rounded py-2 px-3 flex-1"
                            onChange={onChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}