// src/components/AddUserModal.js
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axiosInstance from '../../../shared/axiosInstance';
import '../../../style/AddUserModal.css';
import AddButton from '../../common/button/AddButton';
import NormalButton from '../../common/button/NormalButton';
import PlatformDropdown from '../../common/drop-down/PlatformDropdown';

function AddTicketModal(props) {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        period: '',
        price: '',
    });
    const token = localStorage.getItem('token');

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleAddTicket = () => {
        if (formData.name && formData.period && formData.price) {
            addTicket();
            props.onClose();
        }
    };

    const addTicket = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };
            const data = {
                name: formData.name,
                period: parseInt(formData.period),
                price: parseInt(formData.price),
                platform_id: parseInt(
                    document.getElementById('selectedPlatformType').value
                ),
            };

            axiosInstance
                .post('/api/v1/ticket/create', data, config)
                .then(() => {
                    window.location.reload();
                    props.onClose();
                });
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }

            console.log(error);
            props.onClose();
        }
    };

    return (
        <div
            className={`modal flex items-center justify-center
    ${props.isOpen ? 'block' : 'invisible'}`}
        >
            <div className="modal-dialog" style={{width: '40%'}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>티켓 추가</h2>
                        <button className="close" onClick={props.onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="p-2 mb-4 bg-gray-100">
                            <label className="m-2">플랫폼 아이디</label>
                            <PlatformDropdown onChange={() => {
                            }}/>
                        </div>
                        <div className="p-2 mb-4 bg-gray-100">
                            <label className="flex block mb-2">이름</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                placeholder="이름을 입력하세요"
                                className="border rounded w-full py-2 px-3"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="p-2 mb-4 bg-gray-100">
                            <label className="m-2">기간</label>
                            <input
                                type="text"
                                id="period"
                                name="period"
                                value={formData.period}
                                placeholder="기간을 입력하세요."
                                className="border rounded w-full py-2 px-3"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="p-2 mb-4 bg-gray-100">
                            <label className="m-2">가격</label>
                            <input
                                type="text"
                                id="price"
                                name="price"
                                value={formData.price}
                                placeholder="가격을 입력하세요."
                                className="border rounded w-full py-2 px-3"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div class="flex justify-end">
                        <AddButton name="추가" onClick={handleAddTicket}/>
                        <NormalButton name="취소" onClick={props.onClose}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddTicketModal;
