import React from "react";
import DatePicker from "react-datepicker";

export function DatePickerInput(props) {
    const {labelName, id, date, setDate} = props;

    return (
        <div className="p-2 bg-gray-100 w-1/2">
            <label className="m-2">{labelName}</label>
            <DatePicker
                id={id}
                selected={date}
                onChange={setDate}
                dateFormat="yyyy-MM-dd"
                className="border text-center rounded py-2 w-full focus:outline-none focus:border-blue-500"
                popperClassName="rounded border shadow-md"
            />
        </div>
    );
}