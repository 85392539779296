// src/components/AddUserModal.js
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import '../../../style/AddUserModal.css';
import AddButton from '../../common/button/AddButton';
import NormalButton from '../../common/button/NormalButton';
import TicketDropdown from '../../common/drop-down/TicketDropdown';
import axiosInstance from "../../../shared/axiosInstance";

function GiveTicketModal(props) {
    const navigate = useNavigate();
    const [tickets, setTickets] = useState([]);
    const [selectTicket, setSelectTicket] = useState({
        name: '',
        period: '',
        price: '',
        platform_name: '',
    });
    const [formData, setFormData] = useState({
        agency_user_id: '',
        ticket_count: '',
    });

    const token = localStorage.getItem('token');
    const page = 1;

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleAddTicket = () => {
        if (formData.ticket_count) {
            addTicket();
        }
    };

    const addTicket = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };

            const data = {
                agency_user_id: props.data.id,
                ticket_id: parseInt(document.getElementById('selectedTicket').value),
                ticket_count: parseInt(formData.ticket_count),
            };

            axiosInstance
                .post('/api/v1/accounts/ticket/create', data, config)
                .then(() => {
                    window.location.reload();
                    props.onClose();
                });
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }
            console.log(error);
            props.onClose();
        }
    };

    const getTicket = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
                params: {
                    page: page,
                },
            };

            axiosInstance
                .get('/api/v1/ticket/all', config)
                .then((response) => {
                    setTickets(response.data.ticket_list);
                    if (response.data.ticket_list.length != 0) {
                        const data = response.data.ticket_list[0];
                        data['platform_name'] = data.platform.name;
                        setSelectTicket(data);
                    }
                });
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }
            console.log(error);
        }
    };

    const ticketDropdownFilter = (selectedOption) => {
        const value = document.getElementById('selectedTicket').value;
        const ticket_id = parseInt(value); //value ? parseInt(value) : 1;
        const filtered = tickets.filter((ticket) => ticket.id === ticket_id);
        filtered[0]['platform_name'] = filtered[0].platform.name;
        setSelectTicket(filtered[0]);
    };

    useEffect(() => {
        getTicket();
    }, []);

    return (
        <div
            className={`modal flex items-center justify-center
            ${props.isOpen ? 'block' : 'invisible'}`}
        >
            <div className="modal-dialog" style={{width: '40%'}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>티켓 부여</h2>
                        <button className="close" onClick={props.onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="p-2 mb-4 bg-gray-100">
                            <label className="m-2">티켓 리스트</label>
                            <TicketDropdown onFilter={ticketDropdownFilter}/>
                        </div>
                        <table className="w-full mb-4 border rounded-[20px] shadow-xl">
                            <thead className="bg-gray-500 text-gray-200">
                            <tr>
                                <th className="text-center border-b-4 border-gray-300 p-2">
                                    이름
                                </th>
                                <th className="text-center border-b-4 border-gray-300 p-2">
                                    기간
                                </th>
                                <th className="text-center border-b-4 border-gray-300 p-2">
                                    가격
                                </th>
                                <th className="text-center border-b-4 border-gray-300 p-2">
                                    플랫폼 정보
                                </th>
                            </tr>
                            </thead>
                            <tbody className="bg-gray-200">
                            <tr
                                className="bg-white m-2"
                                onClick={() => {
                                }}
                            >
                                <td className="text-center border-b-4 border-gray-300 p-4">
                                    {selectTicket.name}
                                </td>
                                <td className="text-center border-b-4 border-gray-300 p-4">
                                    {selectTicket.period}
                                </td>
                                <td className="text-center border-b-4 border-gray-300 p-4">
                                    {selectTicket.price}
                                </td>
                                <td className="text-center border-b-4 border-gray-300 p-4">
                                    {selectTicket.platform_name}
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <div className="p-2 mb-4 bg-gray-100">
                            <label className="flex block mb-2">추가 대상</label>
                            <input
                                type="text"
                                id="agency_user_id"
                                name="agency_user_id"
                                value={props.data.name}
                                className="border rounded w-full py-2 px-3"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="p-2 mb-4 bg-gray-100">
                            <label className="m-2">수량</label>
                            <input
                                type="text"
                                id="ticket_count"
                                name="ticket_count"
                                value={formData.ticket_count}
                                placeholder="수량을 입력하세요."
                                className="border rounded w-full py-2 px-3"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div class="flex justify-end">
                        <AddButton name="추가" onClick={handleAddTicket}/>
                        <NormalButton name="취소" onClick={props.onClose}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GiveTicketModal;
