// src/components/FilterDropdown.js
import React, {useState} from 'react';

const SlotFilterDropdown = ({onFilter, userType, statusOption, setStatusOption}) => {

    const renderOptions = () => {
        return (
            <>
                <option value="0">모두</option>
                <option value="1">대기중</option>
                <option value="2">진행중</option>
                <option value="3">완료됨</option>
                <option value="4">비활성화</option>
            </>
        );
    };

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setStatusOption(value);
    };

    return (
        <div className="inline-block flex items-center">
            <select
                value={statusOption}
                onChange={handleOptionChange}
                className="border rounded py-2 px-3"
            >
                {renderOptions()}
            </select>
        </div>
    );
};

export default SlotFilterDropdown;
