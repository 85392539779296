// src/components/AddUserModal.js
import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useNavigate} from 'react-router-dom';
import axiosInstance from '../../../shared/axiosInstance';
import '../../../style/AddUserModal.css';
import AddButton from '../../common/button/AddButton';
import AdvertiserDropdown from '../../common/drop-down/AdvertiserDropdown';
import AgencyDropdown from '../../common/drop-down/AgencyDropdown';
import CancelButton from '../../common/button/CancelButton';
import NormalButton from '../../common/button/NormalButton';
import PlatformDropdown from '../../common/drop-down/PlatformDropdown';
import ErrorModal from "../../common/ErrorModal";

function AddSlotPeriodModal(props) {
    const navigate = useNavigate();
    const [userTicket, setUserTicket] = useState([]);
    const [selectedRow, setSelectedRow] = useState(-1);
    const [createDate, setCreateDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const token = localStorage.getItem('token');
    const [selectTicket, setSelectTicket] = useState({
        name: '',
        period: '',
        price: ''
    });
    const [platformId, setPlatformId] = useState(-1);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const openModal = () => {
        setIsErrorModalOpen(true);
    };

    const closeModal = () => {
        setIsErrorModalOpen(false);
    };

    const handleAddSlotPeriod = async () => {
        const missingFields = [];
        if (parseInt(platformId) === -1) {
            missingFields.push('선택된 플랫폼 타입');
        }
        if (selectedRow === -1) {
            missingFields.push('선택된 티켓');
        }

        if (missingFields.length === 0) {
            let data = {
                'agency_id': parseInt(props.data.userId),
                'used_ticket_id': parseInt(userTicket[selectedRow].ticket.id)
            };

            props.handlePeriodSlotAll(data);
            props.onClose();
        } else {
            const errorMessage = `입력되지 않은 항목: ${missingFields.join(', ')}`;
            setErrorMessage(errorMessage);
            openModal();
        }
    };

    const getUserTicket = async (userId, platformId) => {
        try {
            if (userId === '' || platformId === '') {
                return;
            }

            console.log(platformId);
            setPlatformId(platformId);

            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };

            const response = await axiosInstance.get(
                `/api/v1/accounts/${userId}/platform/${platformId}/ticket/detail`,
                config
            );

            setUserTicket(response.data.user_ticket_list);
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }
            console.log(error);
        }
    };

    // const handleRowClick = (index) => {
    //     if (selectedRow === index) {
    //         setSelectedRow(-1);
    //         setEndDate(createDate);
    //     } else {
    //         setSelectedRow(index);
    //         const tempEndDate = new Date(createDate);
    //         tempEndDate.setDate(createDate.getDate() + userTicket[index].ticket.period);
    //         setEndDate(tempEndDate);
    //     }
    // };

    // 예를들어, 티켓 기간이 10일이고 시작일이 4월 1일이라면, 종료일은 4월 10일이어야 함. 그런데 기존에는 종료일이 4월 11일로 나오는 오류가 있음. 이걸 수정, 2024-04-01 KDW
    const handleRowClick = (index) => {
        if (selectedRow === index) {
            setSelectedRow(-1);
            setEndDate(createDate);
        } else {
            setSelectedRow(index);
            const tempEndDate = new Date(Date.UTC(createDate.getFullYear(), createDate.getMonth(), createDate.getDate()));
            tempEndDate.setUTCDate(tempEndDate.getUTCDate() + userTicket[index].ticket.period - 1);
            setEndDate(tempEndDate);
        }
    };

    const calcTotalPeriod = () => {
        return selectedRow === -1 ? 0 : userTicket[selectedRow].ticket.period;
    };

    const handleCreateDateChange = (date) => {
        const dist = calcTotalPeriod();
        setCreateDate(date);
        const tempEndDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        tempEndDate.setUTCDate(tempEndDate.getUTCDate() + dist);
        setEndDate(tempEndDate);
    };

    // useEffect(() => {
    //     const dist = calcTotalPeriod();
    //     const end = new Date(createDate);
    //     end.setDate(createDate.getDate() + dist);
    //     setEndDate(end);
    // }, [selectTicket]);

    return (
        <div
            className={`modal flex items-center justify-center
    ${props.isOpen ? 'block' : 'invisible'}`}
        >
            <div className="modal-dialog w-1/3 mx-auto">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>기간 연장</h2>
                        <button className="close" onClick={props.onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body flex flex-1">
                        <div className="flex flex-col flex-1 space-y-4">
                            <div className='flex'>
                                <div>
                                    {/* 기간(시작일) 선택 */}
                                    <div className="p-2 bg-gray-100">
                                        <label className="m-2 font-bold">티켓 선택</label>
                                    </div>
                                    <div className="flex">
                                        <div className="p-2 bg-gray-100">
                                            <label className="m-2">플랫폼</label>
                                            <PlatformDropdown
                                                userId={localStorage.getItem('user_id')}
                                                onChange={getUserTicket}
                                            />
                                        </div>
                                        <div className="p-2 bg-gray-100">
                                            <label className="m-2">시작일</label>
                                            <DatePicker
                                                id="createDate"
                                                selected={createDate}
                                                onChange={handleCreateDateChange}
                                                dateFormat="yyyy-MM-dd"
                                                className="border text-center rounded py-2 w-full focus:outline-none focus:border-blue-500"
                                                popperClassName="rounded border shadow-md"
                                            />
                                        </div>
                                        <div className="p-2 bg-gray-100">
                                            <label className="m-2">종료일</label>
                                            <DatePicker
                                                id="endDate"
                                                selected={endDate}
                                                dateFormat="yyyy-MM-dd"
                                                className="border text-center rounded py-2 w-full focus:outline-none focus:border-blue-500"
                                                popperClassName="rounded border shadow-md"
                                            />
                                        </div>
                                    </div>

                                    {/* 티켓 추가 */}
                                    <div className="flex">
                                        <table className="w-full mb-4 border rounded-[20px] shadow-xl overflow-y-auto">
                                            <thead className="bg-gray-500 text-gray-200">
                                            <tr>
                                                <th className="text-center border-b-4 border-gray-300 p-2">
                                                    이름
                                                </th>
                                                <th className="text-center border-b-4 border-gray-300 p-2">
                                                    기간
                                                </th>
                                                <th className="text-center border-b-4 border-gray-300 p-2">
                                                    수량
                                                </th>
                                                <th className="text-center border-b-4 border-gray-300 p-2">
                                                    가격
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="bg-gray-200">
                                            {userTicket.length !== 0 ? (
                                                userTicket.map((ticket, index) => (
                                                    <>
                                                        <tr
                                                            className={`m-2 ${
                                                                index === selectedRow ? `bg-gray-300` : `bg-white`
                                                            }`}
                                                            onClick={() => handleRowClick(index)}
                                                        >
                                                            <td className="text-center border-b-4 border-gray-300 p-4">
                                                                {ticket.ticket.name}
                                                            </td>
                                                            <td className="text-center border-b-4 border-gray-300 p-4">
                                                                {ticket.ticket.period}
                                                            </td>
                                                            <td className="text-center border-b-4 border-gray-300 p-4">
                                                                {ticket.count}
                                                            </td>
                                                            <td className="text-center border-b-4 border-gray-300 p-4">
                                                                {ticket.ticket.price}
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))
                                            ) : (
                                                <tr className="m-2 bg-white">
                                                    <td
                                                        colSpan="4"
                                                        className="text-center border-b-4 border-gray-300 p-4"
                                                    >
                                                        <CancelButton
                                                            name="티켓이 없습니다."
                                                            onClick={() => {
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <AddButton name="추가" onClick={handleAddSlotPeriod}/>
                        <NormalButton name="취소" onClick={props.onClose}/>
                    </div>
                </div>
            </div>
            <ErrorModal
                isOpen={isErrorModalOpen === true}
                onClose={closeModal}
                message={errorMessage}
            />
        </div>
    );
}

export default AddSlotPeriodModal;
