// src/components/Login.js
import axios from 'axios';
import {React, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axiosInstance from '../../shared/axiosInstance';
import ErrorModal from "./ErrorModal";

function Login(handleLogin) {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        nickname: '',
        password: '',
    });
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const openModal = () => {
        setIsErrorModalOpen(true);
    };

    const closeModal = () => {
        setIsErrorModalOpen(false);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/api/v1/accounts/login', formData, {
                withCredentials: true,
            });

            if (response.status === 200) {
                const accessToken = response.data.access_token;
                localStorage.setItem('token', accessToken);
                localStorage.setItem('nickname', formData.nickname);
                localStorage.setItem('user_type', response.data.type);
                localStorage.setItem('user_id', response.data.id);
                axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

                switch (response.data.type) {
                    case 3:
                        navigate('/admin/user-board', {replace: true});
                        break;
                    case 1:
                        navigate('/agency/user-board', {replace: true});
                        break;
                    case 2:
                        navigate('/adv/slot-board', {replace: true});
                        break;
                }
            }
        } catch (error) {
            setErrorMessage(error.response.data);
            openModal();
            console.log(error);
        }
    };

    return (
        <div className="flex flex-col justify-center items-center h-screen">
            <img src="../../logo.png" width="15%" height="15%" alt="로고 이미지"/>
            <div style={{backgroundColor: 'rgb(13, 29, 52)'}} className="p-8 rounded shadow-lg w-96">
                <div className="flex text-white font-bold justify-center">
                    <h2 className="text-2xl mb-4">To-the-moon</h2>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="font-semibold mb-4">
                        <label
                            htmlFor="nickname"
                            className="flex text-white mb-2 block text-sm"
                        >
                            ID
                        </label>
                        <input
                            type="text"
                            id="nickname"
                            name="nickname"
                            value={formData.nickname}
                            onChange={handleChange}
                            className="border rounded w-full py-2 px-3"
                            placeholder="아이디를 입력하세요."
                        />
                    </div>
                    <div className="font-semibold mb-4">
                        <label
                            htmlFor="password"
                            className="flex text-white mb-2 block text-sm"
                        >
                            PW
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="border rounded w-full py-2 px-3"
                            placeholder="비밀번호를 입력하세요."
                        />
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                        >
                            로그인
                        </button>
                    </div>
                </form>
            </div>
            <ErrorModal
                isOpen={isErrorModalOpen === true}
                onClose={closeModal}
                message={errorMessage}
            />
        </div>
    );
}

export default Login;
