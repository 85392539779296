// src/components/AddUserModal.js
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useNavigate} from 'react-router-dom';
import '../../../style/AddUserModal.css';
import AddButton from '../../common/button/AddButton';
import CancelButton from '../../common/button/CancelButton';
import NormalButton from '../../common/button/NormalButton';
import UpdateButton from '../../common/UpdateButton';
import axiosInstance from "../../../shared/axiosInstance";

function SlotModal(props) {
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const token = localStorage.getItem('token');

    const handleChange = (e) => {
        const {name, value} = e.target;
        props.setData({...props.data, [name]: value});
    };

    const handleCreateDateChange = (date) => {
        const dist = endDate.getDate() - startDate.getDate();
        setStartDate(date);
        //setEndDate(new Date(date.setDate(date.getDate() + dist)));
    };

    const handleEndDateChange = (date) => {
        const dist = endDate.getDate() - startDate.getDate();
        setEndDate(date);
        //setStartDate(new Date(date.setDate(date.getDate() - dist)));
    };

    const updateUser = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: 'Bearer ' + token,
                },
            };

            const data = {
                start_date: `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`,
                end_date: `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}`,
                keyword: props.data.keyword != null ? props.data.keyword : "",
                url: props.data.url != null ? props.data.url : "",
                compare_url: props.data.compare_url != null ? props.data.compare_url : "",
                public_memo: props.data.public_memo != null ? props.data.public_memo : "",
                private_memo: props.data.private_memo != null ? props.data.private_memo : "",
            };

            const userId = props.data.userId;
            const slotId = props.data.id;

            axiosInstance
                .patch(`/api/v1/accounts/${userId}/slots/${slotId}/agency/info`, data, config)
                .then(() => {
                    window.location.reload();
                    props.onClose();
                })
        } catch (error) {
            if (error.response.statusText === 'Unauthorized') {
                navigate('/');
                localStorage.removeItem('token');
                localStorage.removeItem('nickname');
            }
            console.log(error);
            props.onClose();
        }
    };

    const historyMessageTypeCheck = (messageType) => {
        switch (messageType) {
            case '슬롯 생성':
                return <AddButton name={messageType} onClick={() => {
                }}/>;
            case '슬롯 수정':
                return <UpdateButton name={messageType} onClick={() => {
                }}/>;
        }
    };

    useEffect(() => {
        if (props.isOpen && props.data) {
            setStartDate(new Date(props.data.start_date));
            setEndDate(new Date(props.data.end_date));
        }
    }, [props.isOpen, props.data]);

    return (
        <div
            className={`modal flex items-center justify-center
    ${props.isOpen ? 'block' : 'invisible'}`}
        >
            <div className="modal-dialog w-1/2 mx-auto" style={{width: '70%'}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>슬롯 정보</h2>
                        <button className="close" onClick={props.onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="grid grid-cols-2 gap-4">
                            {/* 1 */}
                            <div className="flex flex-col space-y-4">
                                <div className="flex flex-1 space-x-2">
                                    <div className="flex-1 p-2 bg-gray-300 flex items-center">
                                        <div className="flex-1">
                                            <label className="m-2">대행사</label>
                                            <input
                                                type="text"
                                                id="agencyName"
                                                name="agencyName"
                                                value={props.data.agency.name}
                                                placeholder="이름을 입력하세요"
                                                className="border rounded w-full py-2 px-3"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-1 p-2 bg-gray-300 flex items-center">
                                        <div className="flex-1">
                                            <label className="m-2">광고주</label>
                                            <input
                                                type="text"
                                                id="advertiserName"
                                                name="advertiserName"
                                                value={props.data.advertiser.name}
                                                placeholder="아이디를 입력하세요."
                                                className="border rounded w-full py-2 px-3"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex space-x-2 flex-1">
                                    <div className="p-2 bg-gray-300 w-1/2 flex items-center">
                                        <div className="flex-1">
                                            <label className="m-2">플랫폼</label>
                                            <input
                                                type="text"
                                                id="platformName"
                                                name="platformName"
                                                value={props.data.platform.name}
                                                placeholder="비밀번호를 입력하세요."
                                                className="border rounded w-full py-2 px-3"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 bg-gray-300 w-1/2 flex items-center">
                                        <div className="flex-1">
                                            <label className="m-2">랭크</label>
                                            <input
                                                type="text"
                                                id="rank"
                                                name="rank"
                                                value={props.data.rank}
                                                placeholder="비밀번호를 재입력하세요."
                                                className="border rounded w-full py-2 px-3"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 2 */}
                            <div className="flex flex-col space-y-2">
                                <div className="flex space-x-2 flex-1">
                                    <div className="p-2 bg-gray-100 w-full">
                                        <label className="m-2">키워드</label>
                                        <input
                                            type="text"
                                            id="keyword"
                                            name="keyword"
                                            value={props.data.keyword}
                                            placeholder="비밀번호를 재입력하세요."
                                            className="border rounded w-full py-2 px-3"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="flex space-x-2 flex-1">
                                    <div className="p-2 bg-gray-100 w-full">
                                        <label className="m-2">상품링크</label>
                                        <input
                                            type="text"
                                            id="url"
                                            name="url"
                                            value={props.data.url}
                                            placeholder="비밀번호를 재입력하세요."
                                            className="border rounded w-full py-2 px-3"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="flex space-x-2 flex-1">
                                    <div className="p-2 bg-gray-100 w-full">
                                        <label className="m-2">가격 비교 상품 링크</label>
                                        <input
                                            type="text"
                                            id="compare_url"
                                            name="compare_url"
                                            value={props.data.compare_url}
                                            placeholder="비교 url을 입력하세요."
                                            className="border rounded w-full py-2 px-3"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="flex space-x-2 flex-1">
                                    <div className="p-2 bg-gray-100 w-1/2">
                                        <label className="m-2">시작일</label>
                                        <DatePicker
                                            id="createdAt"
                                            selected={startDate}
                                            onChange={handleCreateDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            className="border text-center rounded py-2 w-full focus:outline-none focus:border-blue-500"
                                            popperClassName="rounded border shadow-md"
                                        />
                                    </div>
                                    <div className="p-2 bg-gray-100 w-1/2">
                                        <label className="m-2">종료일</label>
                                        <DatePicker
                                            id="endDate"
                                            selected={endDate}
                                            onChange={handleEndDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            className="border text-center rounded py-2 w-full focus:outline-none focus:border-blue-500"
                                            popperClassName="rounded border shadow-md"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* 3 */}
                            <div className="flex">
                                <div className="flex flex-1 space-x-2">
                                    <div className="flex flex-1">
                                        <div className="p-2 bg-gray-100 flex flex-col flex-1">
                                            <label className="m-2">공개 메모</label>
                                            <textarea
                                                id="public_memo"
                                                name="public_memo"
                                                value={props.data.public_memo}
                                                rows="4"
                                                placeholder="이름을 입력하세요"
                                                className="border rounded py-2 px-3 flex-1"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 4 */}
                            <div className="flex">
                                <div className="flex flex-1 space-x-2">
                                    <div className="flex flex-1">
                                        <div className="p-2 bg-gray-100 flex flex-col flex-1">
                                            <label className="m-2">비공개 메모</label>
                                            <textarea
                                                id="private_memo"
                                                name="private_memo"
                                                value={props.data.private_memo}
                                                rows="4"
                                                placeholder="이름을 입력하세요"
                                                className="border rounded py-2 px-3 flex-1"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <div class="flex">
                            <UpdateButton name="정보 수정" onClick={updateUser}/>
                        </div>

                        <div class="flex">
                            <NormalButton name="취소" onClick={props.onClose}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SlotModal;
